/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, StatisticsDetails } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Statistic<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Statistic
     * @name GetStatistics
     * @summary Get statistics by sector code
     * @request GET:/v1/statistics
     * @secure
     */
    getStatistics = (
        query: {
            /** @format date-time */
            from: string
            /** @format date-time */
            to: string
            codeSector?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<StatisticsDetails, ApiErrorsResponse>({
            path: `/v1/statistics`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}
