/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Batching<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Batching
     * @name SetSectorAsDirty
     * @request PUT:/v1/optimize/dirty
     * @secure
     */
    setSectorAsDirty = (
        query?: {
            sectorCode?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<any, ApiErrorsResponse>({
            path: `/v1/optimize/dirty`,
            method: 'PUT',
            query: query,
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags Batching
     * @name OptimizeSector
     * @summary Optimise un secteur
     * @request POST:/v1/optimize/run
     * @secure
     */
    optimizeSector = (
        query?: {
            sectorCode?: string
        },
        params: RequestParams = {}
    ) =>
        this.request<any, ApiErrorsResponse>({
            path: `/v1/optimize/run`,
            method: 'POST',
            query: query,
            secure: true,
            ...params
        })
}
