import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { ROUTES } from 'resources/routes-constants';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';

/**
 * Redirects to the home page if the user is already authenticated.
 * @param {React.ReactNode} children - The child components to render if the user is not authenticated.
 */
const RequireNotAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const currentUser = useSelector((state: RootState) => state.userProfil.currentUser);
    const location = useLocation();

    if (currentUser) {
        const isAdmin = currentUserIsAdmin();
        return <Navigate to={isAdmin ? ROUTES.ADMIN.DASHBOARD : ROUTES.DASHBOARD} state={{ from: location }} replace />;
    }

    return children;
};

export default RequireNotAuth;
