/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ApiErrorsResponse, MissionOptimizedDetailsPagedListResult } from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class MissionOptimized<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags MissionOptimized
     * @name GetMissions
     * @summary Search for missions
     * @request GET:/v1/mission/search
     * @secure
     */
    getMissions = (
        query?: {
            /** @format int32 */
            page?: number
            /** @format int32 */
            pageSize?: number
            sortColumn?: string
            sortDirection?: string
            sector?: string
            /** Get missions for a specific batch */
            batchId?: string
            /** @format int32 */
            poolId?: number
        },
        params: RequestParams = {}
    ) =>
        this.request<MissionOptimizedDetailsPagedListResult, ApiErrorsResponse>({
            path: `/v1/mission/search`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        })
}
