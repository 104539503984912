import {chronoActions} from '../chronodriveSlice';
import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";
import { SiteSettingDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import { ThrunkApiCallBacks } from 'models/ThrunkApiCallbacks';
import apiErrorModel from 'backend/utils/apiErrorModel';


export const createOrUpdateSetting = (
  setting: SiteSettingDetails,
  callbacks: ThrunkApiCallBacks<SiteSettingDetails>
): AppThunk => {
return async (dispatch, getState) => {
    try {

      const tenant = getState().userProfil.currentTenant?.tenant?? ""
      const baseUrl = getState().middlewareApis.middlewareSelected?.baseUrl ?? "";
      const currentMap = getState().map.currentMap;
      const mapId = currentMap?.reference ?? currentMap?.id ?? "";

      dispatch(chronoActions.setLoadingFormSettings(true));

      const settingUpdated = await apiChronodriveDefinition(baseUrl , tenant , mapId)
                                .settings.createOrUpdateSetting(setting.sectorCode, setting as SiteSettingDetails);

      // Set the sector codes in the store
      dispatch(chronoActions.addOrUpdateSetting(settingUpdated.data));
      callbacks.onSuccess(settingUpdated.data);
    } catch (error: any) {
      
      callbacks.onError({ errors:{"api":[error.message]} } as apiErrorModel)

      if (!error.status || error.status != 401) {
        console.error("[createOrUpdateSettings] Error while deleteing site settings", error);
      }
    } finally {
      // Set loading state back to false after the operation completes
      dispatch(chronoActions.setLoadingFormSettings(false));
    }
  };
};


