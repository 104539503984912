import _ from 'lodash';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'store/store';
import nameOf from 'utility/nameOf';
import { downloadFile } from 'utility/file/downloadFile';
import ButtonFno from './inputs/ButtonFno';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


type Props = {
    csvFileUrl: string;
    title: string;
  };


const CsvDataTableRawByUrl: React.FC<Props> = ({csvFileUrl, title}) => {
    const { t } = useTranslation(nameOf({CsvDataTableRawByUrl}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [headers, setHeaders] = useState<TableColumn<any>[]>([]);
    const [rows, setRows] = useState<any[]>([]);


    useEffect(() => {
        Papa.parse<any[]>(csvFileUrl, {
            download: true,
            worker: true,
            header: true,
            error(error) {
                toast.error(t("Impossible de lire et d'extraire le contenu du fichier CSV"))
            },
            complete(results) {
                const headersData: TableColumn<any>[] = []
                _.each(results.meta.fields, (field) => {
                    headersData.push({
                        id:field,
                        name: field,
                        allowOverflow: false,
                        sortable: true,
                        minWidth: '130px',
                        selector: row => row[field]
                    })
                });
                setHeaders(headersData);
                setRows(results.data.map((row) => {
                    return {
                        ...row,
                        // merge all fields in one for search purpose
                        searchField: _.join(_.values(row), " ").toLocaleLowerCase()
                    }
                }));
            },
        });
   }, [csvFileUrl])


   const onDownloadFile = () => {
        const fileName = title + ".csv";
        downloadFile(csvFileUrl, fileName, false)
   }


   let rowData = rows
   if(searchTerm)
   {
       rowData = rows.filter((entity) => {
           return entity.searchField.includes(searchTerm.toLocaleLowerCase());
       });
   }

   return (
       <div className='table-component'>
           <DataTable 
                className={""}
                dense
                columns={headers} 
                data={rowData} 
                striped={true}
                subHeader={true}
                paginationPerPage={15}
                highlightOnHover={true}
                pagination
                noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune ligne")}</div>}
                subHeaderComponent={<> 
                    <h5 className='text-uppercase'> {title + " ("+rowData.length+")"}</h5>
                    <div className='search-panel'>
                        <ButtonFno sm className='me-2' onClick={onDownloadFile}><FontAwesomeIcon className='me-2' icon={["fas", "download"]} /> {t("Télécharger")}</ButtonFno>
                        <input placeholder={t("Rechercher")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                    </div>
                    </>}/>
       </div>
   )
}
export default CsvDataTableRawByUrl