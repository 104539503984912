import { RouteObject } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import Dashboard from 'pages/Client/Dashboard';
import ManageUsers from 'pages/Client/ManageUsers';
import ManageTokens from 'pages/Client/ManageTokens';
import AuditDashboard from 'pages/Client/Audit/AuditDashboard';
import AuditRawDataList from 'pages/Client/Audit/AuditRawDataList';
import AuditBatching from 'pages/Client/Audit/AuditBatching';
import AuditFinalResult from 'pages/Client/Audit/AuditFinalResult';
import AuditPathCompare from 'pages/Client/Audit/AuditPathCompare';
import AuditFinalPathClientResult from 'pages/Client/Audit/AuditFinalPathClientResult';
import AuditHeatmapCompare from 'pages/Client/Audit/AuditHeatmapCompare';
import MapEditor from 'pages/Client/MapEditor/MapEditor';
import BatchingDashboard from 'pages/Client/Middleware/Batching/BatchingDashboard';
import MiddlewareStatisticsBatching from 'pages/Client/Middleware/Batching/MiddlewareStatisticsBatching';
import BatchingFilesImport from 'pages/Client/Middleware/Batching/BatchingFilesImport';
import MiddlewarePickingDashboard from 'pages/Client/Middleware/Picking/MiddlewarePickingDashboard';
import MiddlewareStatisticsPicking from 'pages/Client/Middleware/Picking/MiddlewareStatisticsPicking';
import MiddlewarePathCompare from 'pages/Client/Middleware/Picking/MiddlewarePathCompare';
import RequireAuth from 'components/Routes/RequireAuth';

export const ClientRoutes: RouteObject[] = [
    { path: ROUTES.DASHBOARD, element: <RequireAuth><Dashboard /></RequireAuth> },
    { path: ROUTES.MANAGE_USERS, element: <RequireAuth><ManageUsers /></RequireAuth> },
    { path: ROUTES.MANAGE_TOKENS, element: <RequireAuth><ManageTokens /></RequireAuth> },
    { path: ROUTES.AUDIT_DASHBOARD, element: <RequireAuth><AuditDashboard /></RequireAuth> },
    { path: ROUTES.AUDIT_RAW_DATA, element: <RequireAuth><AuditRawDataList /></RequireAuth> },
    { path: ROUTES.AUDIT_PATH_COMPARE, element: <RequireAuth><AuditPathCompare /></RequireAuth> },
    { path: ROUTES.AUDIT_BATCHING, element: <RequireAuth><AuditBatching /></RequireAuth> },
    { path: ROUTES.AUDIT_FINAL_PATH_CLIENT_RESULT, element: <RequireAuth><AuditFinalPathClientResult /></RequireAuth> },
    { path: ROUTES.AUDIT_FINAL_RESULT, element: <RequireAuth><AuditFinalResult /></RequireAuth> },
    { path: ROUTES.AUDIT_HEATMAP_COMPARE, element: <RequireAuth><AuditHeatmapCompare /></RequireAuth> },
    { path: ROUTES.MAP_EDITOR, element: <RequireAuth><MapEditor /></RequireAuth> },
    { path: ROUTES.BATCHING_DASHBOARD, element: <RequireAuth><BatchingDashboard /></RequireAuth> },
    { path: ROUTES.BATCHING_FILES_IMPORT, element: <RequireAuth><BatchingFilesImport /></RequireAuth> },
    { path: ROUTES.BATCHING_STATISTICS, element: <RequireAuth><MiddlewareStatisticsBatching /></RequireAuth> },
    { path: ROUTES.PICKING_DASHBOARD, element: <RequireAuth><MiddlewarePickingDashboard /></RequireAuth> },
    { path: ROUTES.PICKING_STATISTICS, element: <RequireAuth><MiddlewareStatisticsPicking /></RequireAuth> },
    { path: ROUTES.PICKING_PATH_COMPARE, element: <RequireAuth><MiddlewarePathCompare /></RequireAuth> },
];
