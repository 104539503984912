import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MissionOptimizedDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import ButtonFno from 'components/inputs/ButtonFno';
import PageHeader from 'components/PageHeader';
import dayjs from 'dayjs';
import _ from 'lodash';
import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { loadRunnerResultsThunk } from 'store/reducers/Chronodrive/thunks/loadRunnerResultsThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import { loadMissionOptimizedThunk } from 'store/reducers/Chronodrive/thunks/loadMissionOptimizedThunk';
import { Button, Form, InputGroup, Offcanvas } from 'react-bootstrap';
import Loading from 'components/Loading';
import "./MissionOptimizedList.scss";

const MissionOptimizedList: React.FC = () => {
   const { t } = useTranslation(nameOf({MissionOptimizedList}), { useSuspense: false});
   const dispatch = useAppDispatch();
    const [poolIds, setPoolIds] = useState<number[] | undefined>(undefined);
    
    const createDefaultOption = (label: string) => ({ value: '', label });
    const {
            loadingMissionOptimizedResults,
            missionOptimized,
            missionOptimizedSearchCriteria,
            siteSettings
    } = useAppSelector(state => state.middlewareChrono);
    const [batchIdSearchTerm, setBatchIdSearchTerm] = useState<string>(missionOptimizedSearchCriteria.batchId ?? "");
    const [poolIdSearchTerm, setPoolIdSearchTerm] = useState<number | undefined>(missionOptimizedSearchCriteria.poolId);

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        currentMap,
    } = useAppSelector(state => state.map);

    useEffect(() => {
        refreshSearch();
    }, [currentMap, middlewareSelected])
   

    const columns: TableColumn<MissionOptimizedDetails>[] = [
        {
            id: 'MissionFnoId',
            name: t('Mission id'),
            sortable: true,
            selector: row => row.missionFnoId ?? "",
        },
        {
            id: 'SectorCode',
            name: t('Secteur'),
            sortable: true,
            selector: row => row.sectorCode ?? "",
        },
        {
            id: 'IsDistributed',
            name: t('Distribué'),
            sortable: false,
            selector: row => row.isDistributed,
            cell: row => <>{row.isDistributed ? <FontAwesomeIcon icon={["fas", "check"]} className='text-success' /> : <FontAwesomeIcon icon={["fas", "times"]} className='text-danger' />}</>
        },
        {
            id: 'PoolIds',
            name: t('Nb pools'),
            sortable: false,
            selector: row => row.poolIds.length,
            cell: row => {
                const nbPools = row.poolIds.length;
                return <>
                    {nbPools > 0 ?<Button size='sm' variant='link' className='p-0' onClick={() => setPoolIds(row.poolIds)}> {nbPools}</Button> : 0}
                </>
            }
        },
        {
            id: 'TotalProducts',
            name: t('totalProducts'),
            sortable: true,
            selector: row => row.totalProducts,
        },
        {
            id: 'RunnerResultId',
            name: t('Batch id'),
            width: '230px',
            sortable: false,
            selector: row => row.runnerResultId,
            cell: row => <Button size='sm' variant='link' className='p-0' onClick={() => 
                {
                    setBatchIdSearchTerm(row.runnerResultId)
                    dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, batchId: row.runnerResultId}))
                }
            }> {row.runnerResultId}</Button>
        },
        {
            id:'DateLimitForPreparation',
            name: t('Date limite'),
            sortable: true,
            selector: row => row.dateLimitForPreparation ?? "",
            cell: row => <>{dayjs(row.dateLimitForPreparation).format("DD/MM/YYYY HH:mm")}</>
        },
        {
            id:'DateCreated',
            name: t('Date création'),
            sortable: true,
            selector: row => dayjs(row.dateCreated).unix() ?? "",
            cell: row => <>{dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm")}</>
        },
        /*{
            id:'actions',
            name: t('Actions'),
            sortable: false,
            center:true,
            cell: (row) => <>
                <Button size='sm' color='blue' className='me-2' onClick={() => setRunnerJsonRequest(row.jsonRunnerRequest)}>
                    <FontAwesomeIcon icon={["fas", "code"]}/> {t("Runner")}</Button>

                <Button size='sm' color='blue' onClick={() => setLogs(row.logs)}>
                    <FontAwesomeIcon icon={["fas", "bars"]}/> {t("Logs")}</Button>
                
            </>
        }*/
    ];
        
        


    const refreshSearch =() => {
        if(currentMap)
        {
            
            dispatch(loadMissionOptimizedThunk(missionOptimizedSearchCriteria));
        }
        
    }


    const sectorCodeOptions = _.map(siteSettings, (sectorCode) => ({ value: sectorCode.sectorCode, label: sectorCode.sectorCode }));
    sectorCodeOptions.unshift(createDefaultOption(t('Tous les secteurs')));


   return (<div id="mission-list">
    <PageHeader title={t("Historique des optimisations")}/>
    <div>
    <TableWithCrudComponent 
            entities={missionOptimized?.items ?? []} 
            columns={columns}
            loadingForm={false}
            loadingList={loadingMissionOptimizedResults}
            serverSide
             onChangePage={(page) => dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, currentPage: page}))}
            onChangeRowsPerPage={(pageSize) => dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, pageSize: pageSize}))}
            onSort={(sortField, sortDirection) => dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, sortColumn: sortField.id?.toString(), sortDirection: sortDirection}))}
            paginationPerPage={missionOptimizedSearchCriteria.pageSize ?? 10}
            paginationTotalRows={missionOptimized?.totalCount ?? 0}
            defaultSortFieldId='DateCreated'
            defaultSortFieldDirection='desc'
            addDefaultActionsColumn={false}
            translations={{
                tableTitle: `${t("Liste des mission optimisé F&O")} (${missionOptimized?.totalCount ?? 0})`,
                noEntityText: t("Aucune mission trouvée"),
                deleteText: (entity) => (""),
                deleteTitle: (entity) => (""),
                updateText: (entity) => (""),

            }}
            customHeader={<div className='d-flex justify-content-between align-items-center'>

                <InputGroup className="me-2 ">
                    <Form.Control placeholder={t("Pool Id")} 
                            value={poolIdSearchTerm ?? ""} 
                            onChange={(e) => setPoolIdSearchTerm(parseInt( e.currentTarget.value))}
                            onBlur={() => dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, poolId: poolIdSearchTerm}))}/>
                    <Button variant="outline-danger" disabled={!poolIdSearchTerm} onClick={() => {
                        setPoolIdSearchTerm(undefined)
                        dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, poolId: undefined}))
                    }}>
                        <FontAwesomeIcon icon={["fas", "xmark"]}/>
                    </Button>
                </InputGroup>

                <InputGroup className="me-2">
                    <Form.Control placeholder={t("Batch Id")} 
                            value={batchIdSearchTerm} 
                            onChange={(e) => setBatchIdSearchTerm( e.currentTarget.value)}
                            onBlur={() => dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, batchId: batchIdSearchTerm}))}/>
                    <Button variant="outline-danger" disabled={!batchIdSearchTerm} onClick={() => {
                        setBatchIdSearchTerm("")
                        dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, batchId: ""}))
                    }}>
                        <FontAwesomeIcon icon={["fas", "xmark"]}/>
                    </Button>
                </InputGroup>

                <Select className='me-2 filter-select' 
                                options={sectorCodeOptions} 
                                defaultValue={sectorCodeOptions[0]}
                                value={sectorCodeOptions.find(option => option.value === missionOptimizedSearchCriteria.sector)} 
                                onChange={code => dispatch(loadMissionOptimizedThunk({...missionOptimizedSearchCriteria, sector: code?.value ?? ""}))}/>

                <ButtonFno className='me-2' color='blue'  onClick={() => refreshSearch()}><FontAwesomeIcon icon={["fas", "refresh"]}/></ButtonFno>
            </div>}
            />
    </div>
    <Offcanvas  show={poolIds != undefined} onHide={() => setPoolIds(undefined)} >
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("Liste des pools de la mission")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <DataTable 
                    dense
                    columns={[{
                        id:"poolId",
                        name: t("Pool Id"),
                        sortable: true,
                        selector: row => row
                    }]} 
                    data={poolIds ?? []} 
                    progressPending={false}
                    progressComponent={<Loading text={t("Chargement")}/>}
                    striped={true}
                    highlightOnHover={true}
                    noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun pool")}</div>}/>
            </Offcanvas.Body>
        </Offcanvas>
</div> )
}
export default MissionOptimizedList