import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { loadAllSiteSettings } from 'store/reducers/Chronodrive/thunks/loadAllSiteSettings';
import { loadPoolsThunk } from 'store/reducers/Chronodrive/thunks/loadPoolsThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import OnlyForCustomer from 'components/Routes/OnlyForCustomer';

const LayoutChrono: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation(nameOf({LayoutChrono}), { useSuspense: false });
    const dispatch = useAppDispatch();
    
    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        currentMap,
    } = useAppSelector(state => state.map);


    useEffect(() => {
        // when middleware or selected map changed
        if(middlewareSelected && currentMap)
        {
            // load all setting for populate dropdown list for search with criteria
            dispatch(loadAllSiteSettings());
        }
    }, [middlewareSelected, currentMap]);
    
    return (
        <OnlyForCustomer customerName="chronodrive">
            {children}
        </OnlyForCustomer>
    );
}
export default LayoutChrono;