/**
 *  Génère un nombre aléatoire entier entre min et max (inclus).
 * @param min 
 * @param max 
 * @returns 
 */
export default function generateRandomInt(min: number, max: number): number {

    // Vérifie que les paramètres sont des nombres valides
    if (typeof min !== 'number' || typeof max !== 'number') {
        throw new Error("Les paramètres min et max doivent être des nombres.");
    }
    
    // Vérifie que min est inférieur ou égal à max
    if (min > max) {
        throw new Error("Le paramètre min doit être inférieur ou égal à max.");
    }
    // Génère un nombre aléatoire entre min et max (inclus)
    return Math.floor(Math.random() * (max - min + 1)) + min;
}