import React from 'react';
import BarStackedChart from 'components/Charts/BarStackedChart';
import appColors from 'resources/appColors';
import { useTranslation } from 'react-i18next';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import _ from 'lodash';
import { StatisticsDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import nameOf from 'utility/nameOf';

const ProductsByDistributionTypeChart: React.FC<{ labels: string[], statistics: StatisticsDetails}> = ({ labels, statistics }) => {
    const { t } = useTranslation(nameOf({ProductsByDistributionTypeChart}), { useSuspense: false});
    const colors = [appColors.red, appColors.green, appColors.blue, appColors.yellow, appColors.orange];

    const allDistributionTypes = _.uniq(
        Object.values(statistics?.nbProductsByDistributionType ?? {}).flatMap(sector => Object.keys(sector))
    );

    const nbRunnerCallsBySectorDatasets = allDistributionTypes.map((distributionType, index) => {

        let color = appColors.blue;
        if (distributionType === "URGENT") color = appColors.red;
        else if (distributionType === "NORMAL") color = appColors.green;

        return {
            data: Object.values(statistics?.nbProductsByDistributionType ?? {}).map(sector => sector[distributionType] ?? 0),
            label: distributionType,
            color: color
        } as IDatasetModel;
    });

    return (
        <div className='table-component'>
            <header className='section-header'><h5 className='text-uppercase'>{t("Répartition entre les pools retournés en mode URGENT ou NORMAL")}</h5></header>
            <BarStackedChart labels={labels} datasets={nbRunnerCallsBySectorDatasets} />
        </div>
    );
}

export default ProductsByDistributionTypeChart;
