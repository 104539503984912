import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequestStageDetails, RunnerResultDetails, SiteSettingDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import ButtonFno from 'components/inputs/ButtonFno';
import MissingLocationCodesCanvas from 'components/MissingLocationCodesCanvas';
import PageHeader from 'components/PageHeader';
import dayjs from 'dayjs';
import _ from 'lodash';
import Select from 'react-select';
import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Dropdown, DropdownButton, Modal, Offcanvas } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { loadRunnerResultsThunk } from 'store/reducers/Chronodrive/thunks/loadRunnerResultsThunk';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import LogsListComponent from 'components/Middleware/LogsListComponent';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import { startBatchingForSectorThunk } from 'store/reducers/Chronodrive/thunks/startBatchingForSectorThunk';

const RunnerResultList: React.FC = () => {
   const { t } = useTranslation(nameOf({RunnerResultList}), { useSuspense: false});
   const dispatch = useAppDispatch();

    const [locationCodesToShow, setLocationCodesToShow] = useState<Record<string, number> | undefined>(undefined);
    const [runnerJsonRequest, setRunnerJsonRequest] = useState<string | undefined>(undefined);
    const [logs, setLogs] = useState<RequestStageDetails[] | undefined>(undefined);
    


    const createDefaultOption = (label: string) => ({ value: '', label });
    
    const {
        runnerResults,
        loadingRunnerResults,
        siteSettings,
        runnerResultSearchCriteria
    } = useAppSelector(state => state.middlewareChrono);

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        currentMap,
    } = useAppSelector(state => state.map);

    useEffect(() => {
        refreshSearch();
    }, [currentMap, middlewareSelected])
   

    const columns: TableColumn<RunnerResultDetails>[] = [
        {
            id:'SectorCode',
            name: t('Secteur'),
            sortable: true,
            selector: row => row.sectorCode ?? "",
        },
        {
            id:'Success',
            name: t('Succès'),
            sortable: false,
            selector: row => row.success,
            cell: row => <>{row.success ? <FontAwesomeIcon icon={["fas", "check"]} className='text-success'/> : <FontAwesomeIcon icon={["fas", "times"]} className='text-danger'/>}</>
        },
        {
            id:'ProcessDurationMs',
            name: t('Durée'),
            sortable: false,
            selector: row => row.processDurationMs ?? "",
            cell: row => <>{Math.trunc(row.processDurationMs)/ 1000} s</>
        },
        {
            id:'results',
            name: t('Nb Missions F&O'),
            sortable: false,
            selector: row => Object.entries(row.results ?? {}).length,
        },
        {
            id:'NbPools',
            name: t('Nb pools/Nb produits'),
            sortable: false,
            selector: row => `${row.nbPools}/${row.nbProducts}`,
        },
        {
            id:'missingLocationCodes',
            name: t('Emplacements inconnus'),
            sortable: false,
            selector: row => Object.entries(row.missingLocationCodes ?? {}).length,
            cell: row => {
                const nbMissing = Object.entries(row.missingLocationCodes ?? {}).length;
                return <>
                    {nbMissing > 0 ?<Button size='sm' variant='link' className='p-0' onClick={() => setLocationCodesToShow(row.missingLocationCodes)}> {nbMissing}</Button> : 0}
                </>
            }
        },
        {
            id:'DateCreated',
            name: t('Date'),
            sortable: true,
            selector: row => row.dateCreated ?? "",
            cell: row => <>{dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm")}</>
        },
        {
            id:'actions',
            name: t('Actions'),
            sortable: false,
            width: '200px',
            center:true,
            cell: (row) => <>
                {currentUserIsAdmin() && <Button size='sm' color='blue' className='me-2' onClick={() => setRunnerJsonRequest(row.jsonRunnerRequest)}>
                    <FontAwesomeIcon icon={["fas", "code"]}/> {t("Runner")}</Button>}

                {currentUserIsAdmin() &&  <Button size='sm' color='blue' onClick={() => setLogs(row.logs)}>
                    <FontAwesomeIcon icon={["fas", "bars"]}/> {t("Logs")}</Button>}
                
            </>
        },

    ];


    const sectorCodeOptions = _.map(siteSettings, (sectorCode) => ({ value: sectorCode.sectorCode, label: sectorCode.sectorCode }));
    sectorCodeOptions.unshift(createDefaultOption(t('Tous les secteurs')));


    const refreshSearch =() => {
        if(currentMap)
        {
            dispatch(loadRunnerResultsThunk(runnerResultSearchCriteria));
        }
        
    }

    const startBatch= (sector: string) => {

        if(sector == "") // send all sectors
            dispatch(startBatchingForSectorThunk(sectorCodeOptions.filter(option => option.value != "").map(option => option.value)));
        else // send only selected sector
            dispatch(startBatchingForSectorThunk([sector]));
    }





   return (<div>
    <PageHeader title={t("Historique des optimisations")}/>
    <div>
    <TableWithCrudComponent 
            entities={runnerResults?.items ?? []} 
            columns={columns}
            loadingForm={false}
            loadingList={loadingRunnerResults}
            serverSide
             onChangePage={(page) => dispatch(loadRunnerResultsThunk({...runnerResultSearchCriteria, currentPage: page}))}
            onChangeRowsPerPage={(pageSize) => dispatch(loadRunnerResultsThunk({...runnerResultSearchCriteria, pageSize: pageSize}))}
            onSort={(sortField, sortDirection) => dispatch(loadRunnerResultsThunk({...runnerResultSearchCriteria, sortColumn: sortField.id?.toString(), sortDirection: sortDirection}))}
            paginationPerPage={runnerResultSearchCriteria.pageSize ?? 10}
            paginationTotalRows={runnerResults?.totalCount ?? 0}
            defaultSortFieldId='DateCreated'
            defaultSortFieldDirection='desc'
            addDefaultActionsColumn={false}
            translations={{
                tableTitle: `${t("Liste des batches")} (${runnerResults?.totalCount ?? 0})`,
                noEntityText: t("Aucun batch trouvé"),
                deleteText: (entity) => (""),
                deleteTitle: (entity) => (""),
                updateText: (entity) => (""),

            }}
            customHeader={<div className='d-flex justify-content-between align-items-center'>


                {currentUserIsAdmin() && <DropdownButton
                    className='me-2'
                    as={ButtonGroup}
                    size='sm'
                    id={`start-batch`}
                    variant={"primary"}
                    title={<><FontAwesomeIcon icon={["fas", "play"]}/> {t("Lancer un batch")}</>}
                >
                    {sectorCodeOptions.map(sector => <Dropdown.Item key={sector.value} onClick={() => startBatch(sector.value)}>{sector.label}</Dropdown.Item>)}
                </DropdownButton>}

                <Select className='me-2 filter-select' 
                                options={sectorCodeOptions} 
                                defaultValue={sectorCodeOptions[0]}
                                value={sectorCodeOptions.find(option => option.value === runnerResultSearchCriteria.sector)} 
                                onChange={code => dispatch(loadRunnerResultsThunk({...runnerResultSearchCriteria, sector: code?.value ?? ""}))}/>

                <ButtonFno className='me-2' color='blue'  onClick={() => refreshSearch()}><FontAwesomeIcon icon={["fas", "refresh"]}/></ButtonFno>
            </div>}
            />
    </div>

    <MissingLocationCodesCanvas 
            loading={false}
            show={locationCodesToShow != undefined}
            onHide={() => setLocationCodesToShow(undefined)}
            locationCodes={locationCodesToShow ?? {}}
        />



        <Offcanvas className="offcanvas-request-details" show={logs != undefined} onHide={() => setLogs(undefined)} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Liste des logs du batch")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <LogsListComponent logs={logs ?? []} />
            </Offcanvas.Body>
        </Offcanvas>





        <Modal dialogClassName='modal-fno' show={runnerJsonRequest != undefined} onHide={() => setRunnerJsonRequest(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>{ t("Json requête du runner")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EditJsonRaw  onCancel={() => setRunnerJsonRequest(undefined)} value={JSON.parse(runnerJsonRequest ?? "{}")} loading={false}/>
            </Modal.Body>
        </Modal>
</div> )
}
export default RunnerResultList