import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import ButtonFno from 'components/inputs/ButtonFno';
import { Formik, FormikHelpers } from 'formik';
import { MiddlewareApiCreate, MiddlewareApiDetails } from 'backend/ApiAdminDefinition/data-contracts';
import { useAppDispatch, useAppSelector } from 'store/store';
import dayjs from 'dayjs';
import DatePicker from "react-datepicker";
import "./StatisticDatesForm.scss";
import { middlewarePickingActions } from 'store/reducers/MiddlewarePicking/middlewarePickingSlice';
import useOutsideClick from 'hooks/useOutsideClick ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { menuDatesSelectorActions } from 'store/reducers/MenuDatesSelector/menuDatesSelectorSlice';

 export interface IStatisticDatesFormInputs{
    from: Date;
    to: Date;
}


const StatisticDatesForm: React.FC = () => {
    const { t } = useTranslation([nameOf({StatisticDatesForm}), "TransverseError"], { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        showInput,
        dateFrom,
        dateTo,
    } = useAppSelector(state => state.menuDatesSelector);

    const [calendarOpen, setCalendarOpen] = React.useState(false);

    const ref = useOutsideClick(() => {
        setCalendarOpen(false);
      });
      
    const onSubmit = async (values: IStatisticDatesFormInputs, {setFieldError}:FormikHelpers<IStatisticDatesFormInputs>) => {

        const adjustedDateFrom = dayjs(values.from).startOf('day').toDate();
        const adjustedDateTo =  dayjs(values.to).endOf('day').toDate();

        

        dispatch(menuDatesSelectorActions.setDates({
            from: adjustedDateFrom, 
            to: adjustedDateTo
        }));
        setCalendarOpen(false);
    };

    const schema = yup.object().shape({
        from: yup.date().required(t("required", { ns:"TransverseError"}) as string),
        to: yup.date().required(t("required", { ns:"TransverseError"}) as string),
      });

     if(!showInput) return <></>

    return (
        <div ref={ref} className='StatisticDatesFormContainer d-flex align-items-center' onClick={() => setCalendarOpen(true)}>
            <div className='icon'><FontAwesomeIcon icon={["fas", "calendar-days"]} /></div>
            <Formik
                validationSchema={schema}
                onSubmit={onSubmit}
                
                initialValues={{
                    from: dayjs(dateFrom).toDate(),
                    to: dayjs(dateTo).toDate(),
                } as IStatisticDatesFormInputs}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    touched,
                    isValid,
                    isSubmitting,
                    errors,
                    submitForm
                }) => {
                    
                    const CustomInput = forwardRef(() => {

                        const totalDaysPeriode = dayjs(values.to).diff(dayjs(values.from), 'day') + 1;

                        let label = `${dayjs(values.from).format("DD/MM/YYYY")} ${t('au')} ${values.to ? dayjs(values.to).format("DD/MM/YYYY") : ""}`;

                        if(dayjs(values.to).isSame(dayjs(), "day")) label = totalDaysPeriode > 1 ? `${totalDaysPeriode} ${t('derniers jours')}` : t('Aujourd\'hui');
                        else if(dayjs(values.to).isSame(values.from)) label = t('Journée du {{date}}', {date: dayjs(values.from).format("DD/MM/YYYY")});


                        return <div className='w-100'>{label}</div>
                    });

                    const setDatesAndSubmit = (from: Date | null, to: Date | null) => {

                        setFieldValue("from", from)
                        setFieldValue("to", to);
                        if(from && to) {
                            // Hack to force submit form. TODO: find sexiest way to do it
                            setTimeout(() => submitForm(), 100)
                        }
                    }


                    return (
                        <div className="StatisticDatesForm">
                                    <div>
                                        <Form.Group>
                                            <label style={{cursor: 'pointer'}}>{t('Période')}</label>
                                            {<DatePicker
                                                className='datePickerInput'
                                                startDate={values.from ? values.from : undefined}
                                                endDate={values.to ? values.to: undefined}
                                                selectsRange
                                                adjustDateOnChange
                                                customInput={<CustomInput />}
                                                onChange={(dates) => {
                                                    const [start, end] = dates;
                                                    setDatesAndSubmit(start, end);
                                                }}
                                                locale="fr"
                                                dateFormat={"dd/MM/yyyy"}
                                                excludeDateIntervals={[{start:dayjs().toDate(), end: dayjs().add(9999, 'years').toDate()}]}
                                                placeholderText={t('Période')}
                                                open={calendarOpen}
                                                onCalendarClose={() => setCalendarOpen(false)}> 
                                                    <Row>
                                                        <Col sm={12} className='mb-2'><ButtonFno className='w-100' sm color='orange' onClick={() => setDatesAndSubmit(dayjs().add(-6, 'day').toDate(), dayjs().toDate())} >{t('7 derniers jours')}</ButtonFno></Col>
                                                        <Col sm={12} className='mb-2'><ButtonFno className='w-100' sm color='orange' onClick={() => setDatesAndSubmit(dayjs().add(-29, 'day').toDate(), dayjs().toDate())} >{t('30 derniers jours')}</ButtonFno></Col>
                                                        <Col sm={12}><ButtonFno className='w-100' sm color='orange' onClick={() => setDatesAndSubmit(dayjs().add(-59, 'day').toDate(), dayjs().toDate())} >{t('60 derniers jours')}</ButtonFno></Col>
                                                    </Row>
                                                </DatePicker>}
                                        </Form.Group>
                                    </div>
                            </div>
                        )
                }}
                </Formik> 
        </div>
    )
}

export default StatisticDatesForm
