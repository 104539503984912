import { useState, useEffect, useRef } from "react";
import {
  HubConnectionBuilder,
  HubConnection,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel,
  ILogger,
  HttpTransportType,
  IHubProtocol,
  IRetryPolicy
} from "@microsoft/signalr";


export interface Options {
    onConnected?: (hub: HubConnection) => void;
    onDisconnected?: (error?: Error) => void;
    onReconnecting?: (error?: Error) => void;
    onReconnected?: (connectionId?: string) => void;
    onError?: (error?: Error) => void;
    enabled?: boolean;
    automaticReconnect?: number[] | IRetryPolicy | boolean;
    httpTransportTypeOrOptions?: IHttpConnectionOptions | HttpTransportType;
    hubProtocol?: IHubProtocol;
    logging?: LogLevel | string | ILogger;
  }

  const DEFAULTS: Options = {
    enabled: true
  };
  const defaultOptions: Options = DEFAULTS;

  
export default function useSignalRHub(hubUrl: string, options?: Options) {
    
  const [signalRHub, setSignalRHub] = useState<HubConnection | null>(null);
  const optionsRef = useRef<Options>({...defaultOptions, ...options});
  const [enabled, setEnabled] = useState<boolean>(optionsRef.current.enabled ?? true);

  const [lastDateRefresh, setLastDateRefresh] = useState(Date.now());

  const refreshHubConnection = () => setLastDateRefresh(Date.now());

  
  useEffect(() => {
    if(options && options.enabled != optionsRef.current.enabled)
    {
        setEnabled(options.enabled ?? false)
    }
    optionsRef.current = {...defaultOptions, ...options};
  }, [options]);

  useEffect(() => {
    if(!optionsRef.current.enabled)
      return;

    let isCanceled = false;

    const hubConnectionSetup = new HubConnectionBuilder();

    if(optionsRef.current.httpTransportTypeOrOptions)
        // @ts-expect-error: We don't need to adhere to the overloads.
      hubConnectionSetup.withUrl(hubUrl, optionsRef.current.httpTransportTypeOrOptions);
    else
      hubConnectionSetup.withUrl(hubUrl);

    if(optionsRef.current.automaticReconnect) {
      if(optionsRef.current.automaticReconnect === true)
        hubConnectionSetup.withAutomaticReconnect();
      else
        // @ts-expect-error: We don't need to adhere to the overloads.
        hubConnectionSetup.withAutomaticReconnect(optionsRef.current.automaticReconnect);
    }

    if(optionsRef.current.logging)
      hubConnectionSetup.configureLogging(optionsRef.current.logging);

    if(optionsRef.current.hubProtocol)
      hubConnectionSetup.withHubProtocol(optionsRef.current.hubProtocol);

    const hubConnection = hubConnectionSetup.build();

    hubConnection.start()
      .then(() => {
        if(isCanceled)
          return hubConnection.stop();

        optionsRef.current.onConnected?.(hubConnection);

        if(optionsRef.current.onDisconnected)
          hubConnection.onclose(optionsRef.current.onDisconnected);

        if(optionsRef.current.onReconnecting)
          hubConnection.onreconnecting(optionsRef.current.onReconnecting);

        if(optionsRef.current.onReconnected)
          hubConnection.onreconnected(optionsRef.current.onReconnected);

        setSignalRHub(hubConnection);
      })
      .catch((error) => {
        if(isCanceled)
          return;

        optionsRef.current.onError?.(error);
      });

    return () => {
      isCanceled = true;

      if(hubConnection.state === HubConnectionState.Connected)
        hubConnection.stop();

      setSignalRHub(null);
    };
  }, [hubUrl, enabled, lastDateRefresh]);

  return  {
    signalRHub, refreshHubConnection
  }
}
