import { Batching } from "./ApiChronodriveDefinition/Batching";
import { MissionOptimized } from "./ApiChronodriveDefinition/MissionOptimized";
import { Pool } from "./ApiChronodriveDefinition/Pool";
import { RunnerResult } from "./ApiChronodriveDefinition/RunnerResult";
import { Settings } from "./ApiChronodriveDefinition/Settings";
import { Statistic } from "./ApiChronodriveDefinition/Statistic";
import getApiMiddlewareConfig from "./utils/getApiMiddlewareConfig";




const apiChronodriveDefinition = (baseUrl: string, tenant: string, siteId: string) => {

    const apiConfig = getApiMiddlewareConfig(baseUrl, tenant, {siteId: siteId});


    return {
        batching: new Batching(apiConfig),
        pool: new Pool(apiConfig),
        settings: new Settings(apiConfig),
        statistic: new Statistic(apiConfig),
        runnerResult: new RunnerResult(apiConfig),
        missionOptimized: new MissionOptimized(apiConfig),
    }
}


export default apiChronodriveDefinition;