import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from './store/store'
import './styles/global.scss'
import i18next from 'i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin'
import { LoadUserInfosThunk } from 'store/reducers/Profil/thrunks/LoadUserInfosThunk'
import RouterWrapper from 'components/Routes/AppRouter'

const RootComponent: React.FC = () => {
  const currentUser = useAppSelector(state => state.userProfil.currentUser);
  const currentCulture = useAppSelector(state => state.userProfil.culture);
  const currentTenant = useAppSelector(state => state.userProfil.currentTenant);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if(currentTenant)
    {
      dispatch(LoadUserInfosThunk(currentTenant.tenant))
    }
  }, [currentTenant])


  useEffect(() => {
    i18next.changeLanguage(currentCulture)
  }, [currentCulture])
  

  useEffect(() => {
    // enable translation saving if current user is admin
    i18next.options.saveMissing = currentUserIsAdmin();
  }, [currentUser])

    return (<>
        <RouterWrapper />
        <ToastContainer />
        </>
    )
}


export default RootComponent