import { ApiErrorsResponse, FileDetails, MapDetails, MiddlewareApiDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import apiMiddlewareDefinition from 'backend/apiMiddlewareDefinition';
import { RequestClientModel } from 'backend/ApiMiddlewareDefinition/data-contracts';
import UploadFileForm from 'components/Admin/forms/UploadFileForm';
import MiddlewareAdminSelector from 'components/Admin/Middleware/MiddlewareAdminSelector';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import ButtonFno from 'components/inputs/ButtonFno';
import PageHeader from 'components/PageHeader';
import MapSelector from 'components/selectors/MapSelector';
import _ from 'lodash';
import Papa from 'papaparse';
import React, { useEffect, useRef, useState } from 'react'
import { Badge, Col, Container, Form, InputGroup, ProgressBar, Row } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { adminMiddlewareRequestActions } from 'store/reducers/Admin/MiddlewareRequests/middlewareRequestSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ApiChronodriveDefinition } from './apiChronodriveDefinition';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import dayjs from 'dayjs';
import generateRandomInt from 'utility/generateRandomInt';
import { loadClientFilesThunk } from 'store/reducers/Admin/FileManager/thrunks/loadClientFilesThunk';
import SelectLarge from 'components/inputs/SelectLarge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import readFileFromUrl from 'utility/file/readFileFromUrl';

interface QueryResult{
    index: number;
    duration: number;
    error: any;
    codeHttp: string;
}


interface PoolChrono {
    siteId: string;
    sectorCode: string;
    orderId: number;
    poolId: number;
    status: string;
    departureTime: string;
    pickings: {
        storageArea: string;
        shelfId: number;
        columnId: number;
        quantity: number;
    }[];
}

interface RequestChrono {
    id: string;
    body: PoolChrono;
    response?: QueryResult;
}


const ChronodriveMiddlewareTest: React.FC = () => {
    const { mapId } = useParams();
    const dispatch = useAppDispatch();
    const {
     selectedMiddleware,
     selectedMap,
 } = useAppSelector(state => state.adminMiddlewareRequest);
     
     const selectedAppClient = useAppSelector(state => state.userProfil.currentTenant);
     const allMiddlewares = useAppSelector(state => state.adminRealtimeMiddleware.middlewares.filter(m => m.clientTenant == selectedAppClient?.tenant));
     const allMaps = useAppSelector(state => state.adminClientMap.maps);
    const [fileSelected, setFileSelected] = useState<File | undefined>(undefined);
    const [requestBodies, setRequestBodies] = useState<RequestChrono[]>([]);
    const [currentDate, setCurrentDate] = useState<string>("");
    const csvFiles = useAppSelector(state => state.adminClientFileManager.files.filter(m => m.fileType == "text/csv"));
    const _queryResponses = useRef<RequestChrono[]>([]);

    // Interval entre chaque envoi de requête (en ms)
    const [intervalSendPoolMs, setIntervalSendPoolMs] = useState(250);

    useEffect(() => {
        dispatch(loadClientFilesThunk());
    }, [selectedAppClient])


    useEffect(() => {
        if(allMiddlewares.length > 0 && !selectedMiddleware)
        {
            dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(allMiddlewares[0]));
        }
    
        if(allMiddlewares.length == 0 && selectedMiddleware)
        {
            dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(undefined));
        }
    }, [allMiddlewares])
 
 const csvHeader = {
    orderId: "orderId",
    poolId: "poolId",
    sectorCode: "sectorCode",
    deliveryShopId: "idSite",
    sendingDatetime: "departureTime",
    storageArea: "storageArea",
    shelfId: "shelfId",
    columnCode: "columnId",
    quantity: "quantity"
};

useEffect(() => {
    if(fileSelected)
    {
        Papa.parse<any[]>(fileSelected, {
            download: true,
            worker: true,
            header: true,
            delimiter: ';',
            error(error) {
                toast.error("Impossible de lire et d'extraire le contenu du fichier CSV")
            },
            complete(results) {

                const fields = results.meta.fields;
                const rows = results.data.filter(m => m != undefined );

                const orders = _.groupBy(rows, csvHeader.poolId);

                const requestBody = _.map(orders, (value, key) => {

                    const firstRow = value[0];

                    const siteId = selectedMap?.reference ?? _.get(firstRow, csvHeader.deliveryShopId) ?? ""
                    const poolId = parseInt(_.get(firstRow, csvHeader.poolId)?? "")

                    const randomMinutes = generateRandomInt(15, 500)

                    return {
                        id:  _.uniqueId(), //siteId + '-' + poolId
                        body: {
                            siteId: siteId,
                            sectorCode: _.get(firstRow, csvHeader.sectorCode) ?? "",
                            orderId: parseInt(_.get(firstRow, csvHeader.orderId) ?? ""),
                            poolId: poolId,
                            status: 'AVAILABLE',
                            departureTime: dayjs().add(randomMinutes, 'minutes').format(),//_.get(firstRow, csvHeader.sendingDatetime) ?? "",
                            pickings: value.map((product) => {
                                return {
                                    storageArea: _.get(product, csvHeader.storageArea) ?? "",
                                    shelfId:  parseInt(_.get(product, csvHeader.shelfId) ?? ""),
                                    columnId: parseInt(_.get(product, csvHeader.columnCode) ?? ""),
                                    quantity: parseInt(_.get(product, csvHeader.quantity) ?? ""),
                                }
                            })
                        } as PoolChrono
                    } as RequestChrono
                })

                setRequestBodies(requestBody);
            },
        });
    }
}, [fileSelected])
 
 
    const onMapChange = (map: MapDetails) => {
         dispatch(adminMiddlewareRequestActions.setSelectedMap(map))
     }
 
     const onMiddlewareChange = (middleware: MiddlewareApiDetails) => {
         dispatch(adminMiddlewareRequestActions.setSelectedMiddleware(middleware))
     }
 
     const resetAll = () => {
         setRequestBodies([]);
         //setQueryResponses([]);
         _queryResponses.current=[];
         setFileSelected(undefined);
     }
 
     const startRequests = async () => {

         _queryResponses.current=[];
         if(selectedMiddleware && selectedAppClient && selectedMap)
         {
            if(!selectedMap.reference)
            {
                toast.error("La map sélectionnée n'a pas de référence")
                return;
            }

            // TODO changer avec ApiChronodriveDefinition provenant du dossier backend
             const apiDefinition = new ApiChronodriveDefinition(selectedMiddleware?.baseUrl, getCurrentUserToken(), selectedMap.reference ?? "");
         
             for (let index = 0; index < requestBodies.length; index++) {

                if(fileSelected == undefined) return;

                const body = requestBodies[index].body;
                if(!body.poolId) {
                    requestBodies[index].response = {
                        duration: 0,
                        codeHttp: "400",
                        index: index,
                        error: {errors: {
                            poolId: ["PoolId is required"]
                        }} as ApiErrorsResponse
                    } as QueryResult;
    
                     _queryResponses.current.unshift(requestBodies[index]);

                    continue;
                }
                let result: Response | undefined = undefined;
                let responseTime = 0;
                let attempts = 0;
                const maxRetries = 3;

                while (attempts < maxRetries) {
                    try {
                        const start = performance.now();
                        const id = `${selectedMap.reference ?? ""}-${body.poolId}`;
                        result = await apiDefinition.put(id, body);
                        const end = performance.now();
                        responseTime = end - start;

                        if (result && result.status !== 500) {
                            break;
                        }
                    } catch (error) {
                        console.error("Request failed:", error);
                    }

                    attempts++;
                    await new Promise(resolve => setTimeout(resolve, 500));
                }

                requestBodies[index].response = {
                    duration: responseTime,
                    codeHttp: result ? result.status.toString() : "500",
                    index: index,
                    error: result && result.ok ? [] : (result ? (await result.json()) as ApiErrorsResponse : { errors: { unknown: ["Unknown error"] } })
                } as QueryResult;

                 _queryResponses.current.unshift(requestBodies[index]);
                 setCurrentDate(dayjs().millisecond().toString());

                 await new Promise(resolve => setTimeout(resolve, intervalSendPoolMs));
             }
         }
     }
     

     const downloadAndSelectFileFromStorage = async (file: FileDetails) => {
        const fileContent = await readFileFromUrl(file.url)
        console.log("file: ", fileContent)
        if(fileContent)
        {
            setFileSelected(fileContent);
        }
    }

     const columns: TableColumn<RequestChrono>[] = [
         {
             id:'id',
             name: 'id',
             selector: row => row.id,
         },
         {
            id:'poolId',
            name: 'poolId',
            selector: row => row.body.poolId,
        },
        {
            id:'orderId',
            name: 'orderId',
            selector: row => row.body.orderId,
        },
        {
            id:'sectorCode',
            name: 'sectorCode',
            selector: row => row.body.sectorCode,
        },
        {
            id:'departureTime',
            name: 'departureTime',
            selector: row => row.body.departureTime,
        },
         {
             id:'nbProduct',
             name: 'Nb produits',
             sortable: true,
             selector: row => row.body.pickings?.length ?? 0,
         },
         {
            id:'Http code',
            name: 'Http code',
            sortable: true,
            selector: row => {

                const label = row.response?.codeHttp ?? "";

                /*if (label == "" && row.response?.error?.errors) {
                    label = Object.values(row.response.error.errors).flat().join(", ");
                }*/

                return label;
            },
        },
        {
            id:'error',
            name: 'Erreur',
            sortable: true,
            selector: row => row.response?.error.len == true,
            cell: (row, index, column, id) =>{
               let result = <Badge bg={"success"}>{"Ok"}</Badge>;

               if(row.response?.error.errors != undefined)
               {
                   const label = Object.values(row.response?.error.errors).flat().join(", ");
                   result = <Badge bg="danger">{label}</Badge>
               }
               return result;
                 
            }
        },
         {
             id:'duration',
             name: 'Temps réponse (ms)',
             selector: row => row.response?.duration ?? "",
             sortable: true,
             cell: (row, index, column, id) =>{
                if((row.response?.duration ?? 0) > 2000) return <Badge bg="danger">{row.response?.duration?.toFixed(2)} ms</Badge>
                else if (row.response?.duration == undefined) return <></>
                else return <Badge bg={"success"}>{row.response?.duration?.toFixed(2)} ms</Badge>   
             }
         }
     ];
 
 

     
     const totalRequest = requestBodies.length;
 
     const totalSuccess =    _.filter(requestBodies, (m) => _.startsWith(m.response?.codeHttp, "2")).length 
     const totalError =      _.filter(requestBodies, (m) => _.startsWith(m.response?.codeHttp, "4")).length
 
     const totalSuccessPourcent =    (totalSuccess / totalRequest ) * 100;
     const totalErrorPourcent =      (totalError/ totalRequest ) * 100;
 
     const responseTimeAverage = _.mean(_.map(_queryResponses.current, (m) => m.response?.duration ?? 0));

    return (
         <div>
             {<PageHeader title={'Test du middleware chronodrive'}/>}
             <Container  fluid>
                 <Row>
                     <Col>
                         <MiddlewareAdminSelector onMiddlewareChange={onMiddlewareChange} middlewareIdSelected={selectedMiddleware?.id}/>
                     </Col>
                     <Col>
                         <MapSelector 
                             allMaps={allMaps} 
                             currentMap={selectedMap} 
                             isClearable
                             onMapChange={onMapChange}/>
                     </Col>
                 </Row>
              </Container>
             {!fileSelected && <Row style={{height: "100%"}}>
                 <Col sm="12">
                     <UploadFileForm  loading={false}   onSubmit={(files) => setFileSelected(files[0])}/>
                 </Col>
                 <Col sm="12" >
                    <div className='fs-3 mb-3 text-center'>OU</div>
                    <div className='mx-auto' style={{width: "400px"}}>
                        <SelectLarge options={csvFiles.map(file => ({...file, name: file.fileName}))} 
                            isDisabled = {csvFiles.length == 0}
                            isClearable={false}
                            placeholder={"Sélectionner un fichier CSV"}
                            noOption={"Aucune fichier CSV"}
                            value={fileSelected} 
                            onChange={downloadAndSelectFileFromStorage} 
                            valueRender={(file) =><div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "file-csv"]} /> 
                                {file.name.length > 30 ? file.name.slice(0, 20) + "...": file.name}
                            </div>}
                            optionRender={(file) => <div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "file-csv"]} />  
                                {file.name}
                            </div>}/>
                    </div>
                 </Col>
             </Row>}
             {fileSelected && <Row>
                 <Col sm="12" className='text-center'>
                     <div>Nombre de requêtes: {totalRequest}</div>
                     <ButtonFno disabled={!selectedMap} color={!selectedMap ? "gray" : "blue"} onClick={startRequests}>{!selectedMap ? "Sélectionner une map ..." : "Envoyer les requêtes"}</ButtonFno>
                     <ButtonFno className='ms-2' color={"orange"} onClick={resetAll}>{"Annuler"}</ButtonFno>
                 </Col>
             </Row>}
 
             {fileSelected && <Row>
                 <Col sm="12" >
                     <ProgressBar className='m-3' max={totalRequest} style={{height: "35px"}}>
                         <ProgressBar animated label={totalSuccessPourcent.toFixed(2) + "%"} variant="success" now={totalSuccessPourcent} key={1} />
                         <ProgressBar animated label={totalErrorPourcent.toFixed(2) + "%"} variant="danger" now={totalErrorPourcent} key={2} />
                     </ProgressBar>
                 </Col>
                 <Col sm="12" >
                     <Row className='text-center'>
                        <Col className='fs-4'>
                            <InputGroup className="">
                                <Form.Control placeholder={"Interval en ms"} 
                                    defaultValue={intervalSendPoolMs} 
                                    onBlur={(e) => { setIntervalSendPoolMs(isNaN(parseInt(e.currentTarget.value)) ? 250 : parseInt(e.currentTarget.value))}}/>
                                    <InputGroup.Text id="basic-addon2">Interval en ms</InputGroup.Text>
                            </InputGroup>

                        </Col>
                         <Col className='fs-4'><Badge bg="primary">Total: {totalRequest} requêtes</Badge></Col>
                         <Col className='fs-4'><Badge bg="secondary">Temps réponse: {responseTimeAverage ? responseTimeAverage.toFixed(2) : "0"} ms</Badge></Col>
                         <Col className='fs-4'><Badge bg="success">Success: {totalSuccess} requêtes</Badge></Col>
                         <Col className='fs-4'><Badge bg="danger">Erreur: {totalError} requêtes</Badge></Col>
                     </Row>
                 </Col>
                 <Col sm="12">
                     <TableWithCrudComponent 
                         entities={requestBodies} 
                         columns={columns}
                         hideIdColumn
                         addDefaultActionsColumn={false}
                         defaultSortFieldId={"index"}
                         serverSide={false}
                         loadingList={false}
                         loadingForm={false}
                         fieldSearchable={(entity) => {
                             return [
                                 entity.id,
                             ]
                         }}
                         translations={{
                             tableTitle: "Requêtes",
                             noEntityText: "Aucune requête",
                             createTitle:  "",
                             createButtonText: "",
                             deleteText: (entity) => "",
                             deleteTitle: (entity) =>  "",
                             updateText: (entity) => "",
                         }}
                 />
                 </Col>
             </Row>}
         </div>
     )
}
export default ChronodriveMiddlewareTest