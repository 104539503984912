import { RequestStageDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';
import dayjs from 'dayjs';
import React, { useEffect } from 'react'
import { Badge } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';



interface OwnProps {
    logs: RequestStageDetails[]
 }

/**
 * LogsListComponent is a React functional component that displays a table of logs 
 * associated with a middleware batching request.
 * 
 * The component expects an array of `RequestStageDetails` 
 * objects. Each object describes a log record, with properties `date`, `stageType`, 
 * `duration`, `isError`, and `message`.
 * 
 * The table displays columns for the date and time of the log, the type of the log, 
 * the duration of the log in seconds, and the log message. If the log is an error, 
 * the message is prefixed with a red "Erreur" badge.
 * 
 * The table is empty if the `logs` array is empty.
 * 
 * @param {OwnProps} props - the properties for this component
 * @param {RequestStageDetails[]} props.logs - the array of log records to display
 * @returns {JSX.Element} - the rendered component
 */
const LogsListComponent: React.FC<OwnProps> = ({logs}) => {
   const { t } = useTranslation(nameOf({LogsListComponent}), { useSuspense: false});

   useEffect(() => {

   }, [])


   const formatJsonMessage = (message: string) => {
    try {
        const json = JSON.parse(message);
        return <pre>{JSON.stringify(json, null, 2)}</pre>;
    } catch (e) {
        return message;
    }
}

   const columnsLogs: TableColumn<RequestStageDetails>[] = [
    {
        id:'date',
        name: t('Heure'),
        width:"150px",
        cell: row => dayjs(row.date).format("HH:mm:ss")
    },
    {
        id:'stageType',
        name: t('Type'),
        width:"250px",
        selector: row => row.stageType ?? "",
        cell: row => {
            const errorBadge = (row.isError ? <Badge bg='danger' className='me-2 ms-2'>{t("Erreur")}</Badge> : <></>);
            return <>{row.stageType ?? ""} {errorBadge}</>
        },

    },
    {
        id:'duration',
        name: t('Durée'),
        width:"150px",
        selector: row => row.duration,
        cell: row => {
            const duration = row.duration > 0 ? row.duration/1000 : 0;

            return  duration > 0 ? duration.toFixed(3)+ " s" : "";
        }
    },
    {
        id:'message',
        name: t('Message'),
        selector: row => row.message ?? "",
        cell: row =>  {
            return <>{formatJsonMessage(row.message)}</>
        }
    }
];


   return (
       <div>
           <DataTable 
                columns={columnsLogs} 
                conditionalRowStyles={[{when: row => row.isError, style: {color: 'red'}}]}
                data={logs} 
                striped={true}
                subHeader={false}
                highlightOnHover={true}
                noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun log")}</div>}
                sortServer={false}/>
       </div>
   )
}
export default LogsListComponent