import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from "react-router-dom";
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { userProfilActions } from 'store/reducers/Profil/userProfilSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import "./MenuClient.scss"
import MapSelector from '../selectors/MapSelector';
import TenantSelector from '../selectors/TenantSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';
import { ROUTES } from 'resources/routes-constants';
import Loading from '../Loading';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import AuditSelector from '../Audit/AuditSelector';
import MiddlewareSelector from '../selectors/MiddlewareSelector';
import StatisticDatesForm from 'pages/Client/Middleware/Picking/StatisticDatesForm';
import checkPermissions from 'utility/checkPermissions';
import { AuditRawFileDetails, ClientItem, MapDetails, PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import { selectTenantForClientThunk } from 'store/reducers/Profil/thrunks/selectTenantForClientThunk';
import { selectCurrentMapThrunks } from 'store/reducers/Map/thrunks/selectCurrentMapThrunks';
import { SelectAuditDataThunk } from 'store/reducers/Audit/thrunks/SelectAuditDataThunk';
import MenuItem from './MenuItem';

/**
 * The MenuClient component is the main menu of the application for the client user.
 * It displays the current user's account name, tenant and map selector, module selector (if enabled),
 * and provides access to different features of the application based on the user's permissions.
 * It also handles the current tenant and map selection.
 *
 * @return {JSX.Element} The MenuClient component.
 */
const MenuClient: React.FC = () => {
    const { t } = useTranslation(nameOf({Menu: MenuClient}), { useSuspense: false});

    const {
        currentUser,
        currentTenant,
        userInfos,
        loadingUserData
      } = useAppSelector(state => state.userProfil);

      const {
        auditRawFiles, 
        auditRawFileSelected
    } = useAppSelector(state => state.audit);


    const moduleMiddlewareEnabled = useAppSelector(state => state.middlewareApis.middlewareSelected != null);

    const {
        currentMap,
        maps
        } = useAppSelector(state => state.map);

    const locationRouter = useLocation();
    const dispatch = useAppDispatch();

    if(!currentUser) return null;

    const moduleAuditEnabled = checkPermissions(PermissionsEnum.AuditList); //(currentMap?.totalAudits ?? 0) > 0;
    const moduleBatchingEnabled = checkPermissions(PermissionsEnum.BatchingRawFileList);
    
    let initial = "";
    let fullName = currentUser.email;

    if(userInfos && userInfos.firstName){
        initial += userInfos.firstName[0];
        fullName = userInfos.firstName;
    }
    if(userInfos && userInfos.lastName){
        initial +=  userInfos.lastName[0];
        fullName += (" " + userInfos.lastName);
    }


    const accountName = <Row id="account-name" className='m-3 align-items-center'>
        <Col className='px-0' xs="auto"><span className="user-photo" >{initial}</span></Col>
        <Col className='text-truncate'>
            <div><span className="user-name lato">{fullName}</span></div>
            {currentUserIsAdmin() && <div><Link to={ROUTES.ADMIN.DASHBOARD} className='admin-btn'>Mode Admin</Link> </div>}
            </Col>
        <Col className='px-0' xs="auto"><button type="button" className='button-transparent' onClick={() => dispatch(userProfilActions.setLogout())}><span className="log-out" /></button></Col>
    </Row>;
    
    const onCurrentTenantChanged = (tenant: ClientItem) => {
        dispatch(selectTenantForClientThunk(tenant));
    }

    const onCurrentMapChaned = (map: MapDetails) => {
        dispatch(selectCurrentMapThrunks(map));
        
        setTimeout(() => {
            location.reload();
        }, 200)
    }

    const onAuditChange = (auditFile: AuditRawFileDetails) => {
        dispatch(SelectAuditDataThunk(auditFile))
    }


    const isAuditPage = locationRouter.pathname.startsWith("/audit/")
    const isMiddlewarePickingPage = locationRouter.pathname.startsWith("/picking/")
    const isMiddlewareBatchingPage = locationRouter.pathname.startsWith("/batching/")
    const isChronodriveCustomer = currentTenant && currentTenant.name.toLowerCase() === "chronodrive";
    const isAuchanCustomer = currentTenant && currentTenant.name.toLowerCase() === "auchan";

    const isTimcodeDemo = ["665f2938ae59a4ee44146edc", "665f19caae59a4ee44146e25"].includes(currentMap?.id ?? ""); // ! id map timcode sur preprod
    const isIvantiDemo = ["666ae43ed7504d9bf101b99e"].includes(currentMap?.id ?? ""); // Heatmap démo sur ivanti preprod
    
    return (
        <div id="menu">
            <div>{accountName}</div>
            <div>
                <TenantSelector 
                    tenants={currentUser.tenants} 
                    currentTenant={currentTenant} 
                    onTenantChange={onCurrentTenantChanged}/>
            </div>
            <div>
                <MapSelector 
                    allMaps={maps} 
                    currentMap={currentMap} 
                    onMapChange={onCurrentMapChaned}/>
            </div>
            {moduleMiddlewareEnabled  && (isMiddlewarePickingPage || isMiddlewareBatchingPage) && <>
                <div><MiddlewareSelector /></div>
            </>}

            <div><StatisticDatesForm /></div>
            
            {moduleAuditEnabled && isAuditPage && 
                <div>
                    <AuditSelector 
                        audits={auditRawFiles}
                        auditSelected={auditRawFileSelected}
                        onAuditChange={onAuditChange}/>
                </div>}
            
            {!loadingUserData && <div className='menu-items mt-5'>
                <MenuItem label={t('Tableau de bord')} to={ROUTES.DASHBOARD} iconName='chart-line' />
                {/*checkPermissions(PermissionsEnum.StatisticsRead) && <MenuItem label={t('Statistiques')} to={ROUTES.STATISTICS} iconName='chart-column' />*/}
                {/*<MenuItem label={t('Statistiques')} to={ROUTES.STATISTICS} iconName='chart-column' />*/}
                
                {/*<MenuItem label={t('Requêtes')} to={ROUTES.PICKING_REQUESTS} iconName='map-location' iconPrefix="fas" />*/}
                {/*<MenuItem label={t('Commandes')} to={ROUTES.PICKING_ORDERS} iconName='box' iconPrefix="fas" />*/}
                {/*<MenuItem label={t('Commandes SAP')} to={ROUTES.SAP_ORDERS_LIST} iconName='box' iconPrefix="fas" />*/}

                {moduleAuditEnabled && checkPermissions(PermissionsEnum.AuditList) && <>
                    <MenuItem label={t('Audits')} to={ROUTES.AUDIT_DASHBOARD} iconName='bars' iconPrefix="fas" />
                    {isAuditPage && <>
                        {checkPermissions(PermissionsEnum.AuditRawData) && <MenuItem label={t('Données client')} to={ROUTES.AUDIT_RAW_DATA} iconName='table' iconPrefix="fas" subLevel={1} />}
                        {checkPermissions(PermissionsEnum.AuditResultPath) && <MenuItem label={t('Résultat itinéraire')} to={ROUTES.AUDIT_PATH_COMPARE} iconName='location-pin' iconPrefix="fas" subLevel={1} />}
                        {checkPermissions(PermissionsEnum.AuditResultBatching) && <MenuItem label={t('Résultat regroupement')} to={ROUTES.AUDIT_BATCHING} iconName='boxes-stacked' iconPrefix="fas" subLevel={1} />}
                        {checkPermissions(PermissionsEnum.AuditResultFinalPath) && <MenuItem label={t('Résultat regroupement chemin')} to={ROUTES.AUDIT_FINAL_PATH_CLIENT_RESULT} iconName='route' iconPrefix="fas" subLevel={1} />}
                        {checkPermissions(PermissionsEnum.AuditResultFinalBatching) && <MenuItem label={t('Résultat final')} to={ROUTES.AUDIT_FINAL_RESULT} iconName='clipboard-check' iconPrefix="fas" subLevel={1} />}
                        {checkPermissions(PermissionsEnum.AuditHeatmapRead) && <MenuItem label={t('Résultat heatmap')} to={ROUTES.AUDIT_HEATMAP_COMPARE} iconName='table-cells' iconPrefix="fas" subLevel={1} />}
                    </>}
                </>}

                {moduleMiddlewareEnabled && <>
                    {checkPermissions(PermissionsEnum.PickingOptimizationMenu) && <>
                        <MenuItem label={t('Picking')} to={ROUTES.PICKING_DASHBOARD} iconName='box-archive' iconPrefix="fas" />
                        {isMiddlewarePickingPage && <>
                            <MenuItem label={t('Statistiques')} to={ROUTES.PICKING_STATISTICS} iconName='chart-column' iconPrefix="fas" subLevel={1} />
                            <MenuItem label={t('Résultat itinéraire')} to={ROUTES.PICKING_PATH_COMPARE} iconName='location-pin' iconPrefix="fas" subLevel={1} />
                        </>}
                    </>}

                    {checkPermissions(PermissionsEnum.BatchingMenu) && <>
                        <MenuItem label={t('Batching')} to={ROUTES.BATCHING_DASHBOARD} iconName='boxes-stacked' iconPrefix="fas" />
                        {isMiddlewareBatchingPage && <>
                            {isChronodriveCustomer && <>
                                <MenuItem label={t('Statistiques')} to={ROUTES.BATCHING_CHRONO_STATISTICS} iconName='chart-column' iconPrefix="fas" subLevel={1} />
                                <MenuItem label={t('Pools')} to={ROUTES.BATCHING_CHRONO_POOLS} iconName='box' iconPrefix="fas" subLevel={1} />
                                <MenuItem label={t('Batches')} to={ROUTES.BATCHING_CHRONO_RUNNER_BATCHES} iconName='code-fork' iconPrefix="fas" subLevel={1} />
                                <MenuItem label={t('Missions')} to={ROUTES.BATCHING_CHRONO_RUNNER_MISSIONS} iconName='list-check' iconPrefix="fas" subLevel={1} />
                                <MenuItem label={t('Configurations')} to={ROUTES.BATCHING_CHRONO_SETTINGS} iconName='gears' iconPrefix="fas" subLevel={1} />
                            </>}
                            {!isChronodriveCustomer && <>
                                <MenuItem label={t('Importer fichiers')} to={ROUTES.BATCHING_FILES_IMPORT} iconName='file-csv' iconPrefix="fas" subLevel={1} />
                                <MenuItem label={t('Statistiques')} to={ROUTES.BATCHING_STATISTICS} iconName='chart-column' iconPrefix="fas" subLevel={1} />
                            </>}
                        </>}
                    </>}
                </>}

                {isTimcodeDemo && <>
                    <MenuItem label={t('Gestion étiquettes')} to={ROUTES.DEMO.TIMCODE.TAG_MANAGER} iconName='tags' iconPrefix="fas" />
                    <MenuItem label={t('Résultat regroupement')} to={ROUTES.DEMO.TIMCODE.BATCHING_RESULT} iconName='boxes-stacked' iconPrefix="fas" />
                    <MenuItem label={t('Résultat Timcod')} to={ROUTES.DEMO.TIMCODE.BATCHING_TIMCODE} iconName='clipboard-check' iconPrefix="fas" />
                </>}

                {isIvantiDemo && <>
                    <MenuItem label={t('Heatmap')} to={ROUTES.DEMO.IVANTI.HEATMAP} iconName='table-cells' iconPrefix="fas" />
                </>}

                {/*checkPermissions(BackofficeFeatures.user_read) && <MenuItem label={t('Gestion des droits')} to={ROUTES.MANAGE_USERS} iconName='user' iconPrefix="far" />*/}
                {/*checkPermissions(BackofficeFeatures.token_read) && <MenuItem label={t('Clés API')} to={ROUTES.MANAGE_TOKENS} iconName='link' />*/}
            </div>}
            {loadingUserData && <div className='mt-5 text-center'><Loading /></div>}
        </div>
    );
}

export default MenuClient
