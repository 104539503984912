import React from 'react';
import LineChart from 'components/Charts/LineChart';
import appColors from 'resources/appColors';
import { useTranslation } from 'react-i18next';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import { StatisticsDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import nameOf from 'utility/nameOf';

const PoolsByMissionsChart: React.FC<{ labels: string[], statistics: StatisticsDetails}> = ({ labels, statistics }) => {
    const { t } = useTranslation(nameOf({PoolsByMissionsChart}), { useSuspense: false});

    const datasetAverage = {
        data: Object.values(statistics?.averagePoolByMissionPerDay ?? {}).map(stat => stat.average),
        label: t("Nb pools moyens/mission"),
        color: appColors.blue,
    } as IDatasetModel;

    const datasetMin = {
        data: Object.values(statistics?.averagePoolByMissionPerDay ?? {}).map(stat => stat.min),
        label: t("Nb pools min"),
        color: appColors.green,
    } as IDatasetModel;

    const datasetMax = {
        data: Object.values(statistics?.averagePoolByMissionPerDay ?? {}).map(stat => stat.max),
        label: t("Nb pools max"),
        color: appColors.orange,
    } as IDatasetModel;

    return (
        <div className='table-component'>
            <header className='section-header'><h5 className='text-uppercase'>{t("Nombre moyen de pools par mission F&O")}</h5></header>
            <LineChart labels={labels} datasets={[datasetAverage, datasetMin, datasetMax]} />
        </div>
    );
}

export default PoolsByMissionsChart;
