import Loading from 'components/Loading';
import React, { useEffect } from 'react'
import { Offcanvas } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';



interface OwnProps {
    loading: boolean;
    show: boolean;
    onHide: () => void;
    locationCodes: Record<string, number>
 }


const MissingLocationCodesCanvas: React.FC<OwnProps> = ({loading, show, locationCodes, onHide}) => {
   const { t } = useTranslation(nameOf({MissingLocationCodesCanvas}), { useSuspense: false});

   useEffect(() => {
   }, [])
   return (<Offcanvas show={show} onHide={onHide} >
   <Offcanvas.Header closeButton>
   <Offcanvas.Title>{t("Liste des adresses inconnues")}</Offcanvas.Title>
   </Offcanvas.Header>
   <Offcanvas.Body>
       <DataTable 
           className={loading ? "loading": ""}
           dense
           columns={[{
               id:"key",
               name: t("Emplacement"),
               sortable: true,
               selector: row => row.key
           },
           {
               id:"value",
               name: t("Nb occurences"),
               sortable: true,
               selector: row => row.value
           }]} 
           data={Object.entries(locationCodes).map(([key, value]) => { return {key, value}})} 
           progressPending={loading}
           progressComponent={<Loading text={t("Chargement")}/>}
           striped={true}
           highlightOnHover={true}
           noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun emplacement inconnu")}</div>}/>
   </Offcanvas.Body>
</Offcanvas>)
}
export default MissingLocationCodesCanvas