import {chronoActions} from '../chronodriveSlice';
import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";
import IPoolSearchCriteria from '../models/IPoolSearchCriteria';


export const loadPoolsThunk = (
    criteria: IPoolSearchCriteria): AppThunk => {
return async (dispatch, getState) => {
  try {
    const tenant = getState().userProfil.currentTenant?.tenant?? ""
    const baseUrl = getState().middlewareApis.middlewareSelected?.baseUrl ?? "";
    const currentMap = getState().map.currentMap;
    const mapId = currentMap?.reference ?? currentMap?.id ?? "";

    dispatch(chronoActions.setLoadingPools(true));
    // set the pool search criteria
    dispatch(chronoActions.setPoolSearchCriteria(criteria));
    
    const searchResult = await apiChronodriveDefinition(baseUrl, tenant, mapId)
                              .pool.getPools({
                                page: criteria.currentPage,
                                pageSize: criteria.pageSize,
                                sortColumn: criteria.sortColumn,
                                sortDirection: criteria.sortDirection,
                                sector: criteria.sector,
                                status: criteria.status,
                                poolId: criteria.poolId,
                                distributionStatus: criteria.distributionEnum});
                    
    // Set the pools in the store
    dispatch(chronoActions.setPoolSearchResult(searchResult.data));

  } catch (error: any) {

    if (!error.status || error.status != 401) {
      console.error("[loadPoolsThunk] Error while loading pools", error);
    }
  } finally {
    // Set loading state back to false after the operation completes
    dispatch(chronoActions.setLoadingPools(false));
  }
};
};