import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import { FormikHelpers } from 'formik';
import { ROUTES } from 'resources/routes-constants';
import { useAppDispatch, useAppSelector } from 'store/store';
import AuditUploadRawFileForm, { AuditSettingFormInputs } from 'components/Audit/AuditUploadRawFileForm';
import { downloadFile } from 'utility/file/downloadFile';
import { AuditRawFileDetails, MapDetails, PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import { createAndUploadAuditRawFileThunk } from 'store/reducers/Audit/thrunks/createAndUploadAuditRawFileThunk';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';
import { deleteAuditRawFileThunk } from 'store/reducers/Audit/thrunks/deleteAuditRawFileThunk';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { runOptimizationProcess } from 'store/reducers/Audit/thrunks/runOptimizationProcess';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { SelectAuditDataThunk } from 'store/reducers/Audit/thrunks/SelectAuditDataThunk';
import { LoadAuditDataThunk } from 'store/reducers/Audit/thrunks/LoadAuditDataThunk';
import checkPermissions from 'utility/checkPermissions';


interface OwnProps {
    showCreateBtn?: boolean;
    showCheckbox?: boolean;
    allMaps: MapDetails[];
    clientId: string;
    mapId: string;
 }

/**
 * Component that displays a list of audit files for a map.
 * It allows to create new audit files, delete existing ones and download them.
 * @name AuditsList
 * @component
 * @param {OwnProps} props - Component props
 * @param {boolean} props.showCheckbox - Indicates if the component should display a checkbox in the first column of the table
 * @param {MapDetails[]} props.allMaps - Array of all maps available for the current client
 * @param {string} props.mapId - ID of the map to display the audit files for
 * @param {string} props.clientId - ID of the client to display the audit files for
 * @return {JSX.Element} The component
 */

const AuditsList: React.FC<OwnProps> = ({allMaps, mapId, clientId, showCheckbox = false}) => {
   const { t } = useTranslation(nameOf({AuditsList}), { useSuspense: false});
   const [selectedEntityToDelete, setSelectedEntityToDelete] = useState<AuditRawFileDetails>();
   const selectedClient = useAppSelector(state => state.userProfil.currentTenant);
   const [showCreateAuditModal, setShowCreateAuditModal] = useState(false);
   const haveEditPermission = checkPermissions(PermissionsEnum.AuditEdit);

   const {
       auditRawFiles: rawFiles,
       loadingList,
       loadingForm,
       auditRawFileSelected
   } =  useAppSelector(state => state.audit)

   const [searchTerm, setSearchTerm] = useState('');
   const dispatch = useAppDispatch();

   useEffect(() => {
       document.title = t("Les fichiers d'audits");
       if(clientId && mapId)
       {
           loadSettings();
       }


   }, [clientId, mapId])

   if(!selectedClient)
   {
       return <Navigate to={ROUTES.ADMIN.CLIENTS} replace />
   }

   const loadSettings = () => {
       if(clientId)
       {
           dispatch(LoadAuditDataThunk(mapId ?? ""))
       }
   }


   const onCreateEntity = async (values: AuditSettingFormInputs, {setFieldError}:FormikHelpers<AuditSettingFormInputs>) => {
       return new Promise<void>((resolve, reject) => {
           if(clientId)
           {
   
               dispatch(createAndUploadAuditRawFileThunk(mapId ?? "", 
                           values as unknown as AuditRawFileDetails, 
                           values.dataCsvFile,
                           values.resultatJsonFile, {
                   onSuccess: (file) => {
                       toast.success(t("L'audit a bien été créé"));
                       if(!values.createAnother) 
                       {
                           setShowCreateAuditModal(false);
                       }
                       resolve();     
                   },
                   onError: (error) => {
                       parseApiErrorSetFieldError(error, setFieldError);
                       reject();
                   }
               }));
           }
           else
           {
                console.warn("Not client id found when saving audit raw data")
                reject();
           }
        });
      
   }


   const onDelete = (file: AuditRawFileDetails) => {
       if(clientId)
       {

           dispatch(deleteAuditRawFileThunk(mapId ?? "", file.id, {
               onSuccess: (success) => {
                   toast.success(t('Le fichier a bien été supprimée'));
                   setSelectedEntityToDelete(undefined);
                           
               },
               onError: (error) => {
                   parseApiErrorToast(error);
               }
           }));
       }
   }

   const computeAudit = async (file: AuditRawFileDetails) => {

       dispatch(runOptimizationProcess(mapId ?? "", file.id, {
           onSuccess: (success) => {
               toast.success(t("Optimisation en cours de calcul"))
               setSelectedEntityToDelete(undefined);
                       
           },
           onError: (error) => {
               parseApiErrorToast(error);
           }
       }));
   }

   const onDownload = (setting: AuditRawFileDetails) => {
       const fileName = setting.name.endsWith(".csv") ? setting.name : setting.name + ".csv";

       toast.success(fileName + " " + t('en cours de téléchargement'));
       downloadFile(setting.downloadUrlDataCsv, fileName)
   }

   const columns: TableColumn<AuditRawFileDetails>[] = [
       {
           id:'name',
           name: t('name'),
           sortable: true,
            compact: true,
            grow: 2,
           selector: row => row.name,
       },
       {
           id:'map',
           name: t('map'),
           sortable: true,
           selector: row => allMaps.find(m => m.id == row.mapId)?.name ?? "",
       },
       {
           id:'totalRows',
           name: t('Total lignes'),
           sortable: true,
           selector: row => row.totalRows,
       },
       {
           id:'totalSupports',
           name: t('Total supports'),
           sortable: true,
           selector: row => row.totalSupports,
       },
       {
           id:'totalMissions',
           name: t('Total missions'),
           sortable: true,
           selector: row => row.totalMissions,
       },
       {
           id:'author',
           name: t('Auteur'),
           sortable: true,
           selector: row => row.author
       },
       {
           id:'dateCreated',
           name: t('Créé le'),
           sortable: true,
           selector: row => `${row.dateCreated ? dayjs(row.dateCreated).unix(): ""}`,
           cell: row => `${row.dateCreated ? dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm"): ""}`
       },
       {
           id:'optimized',
           name: t('Optimisé'),
           sortable: true,
           center: true,
           selector: row => !!row.auditResultId,
           cell: row => row.auditResultId ? <strong className='text-success '> <FontAwesomeIcon icon={["fas", "check"]} /></strong>:
                                           <strong className='text-danger'><FontAwesomeIcon icon={["fas", "xmark"]} /></strong>
       },
       {
           name:<div>{t('Actions')}</div>,
           center:true,
           width: haveEditPermission ? "200px" : "100px",
           cell: (row, index, column, id) =>{
               return <div>
                    {haveEditPermission && <Button size='sm' variant='secondary' title={t("Lancer le calcul d'optimisation")} className='ms-1' onClick={() => computeAudit(row)}>
                       <FontAwesomeIcon icon={["fas", "gears"]} />
                   </Button>}
                    <Button size='sm' variant='primary' title={t('Télécharger le fichier source CSV')} className='ms-1' onClick={() => onDownload(row)}>
                       <FontAwesomeIcon icon={["fas", "download"]} /> csv
                   </Button>
                   {haveEditPermission && <Button size='sm' variant='danger' className='ms-1' onClick={() => setSelectedEntityToDelete(row)}>
                       <FontAwesomeIcon icon={["fas", "trash"]} />
                   </Button>}
               </div>
           }
       }
   ];


   // Add checkbox in first column
   if(showCheckbox)
   {
        
       columns.unshift( {
        width:"50px",
        center:true,
        cell: (row, index, column, id) =>{
            return <div>
                {row.id == auditRawFileSelected?.id ? 
                    <span className='checkbox selected'><FontAwesomeIcon icon={["fas", "square-check"]} size="lg"/> </span>:
                    <span className='checkbox' onClick={() => dispatch(SelectAuditDataThunk(row))}><FontAwesomeIcon icon={["fas", "square"]} size="lg"/></span>}
            </div>
        }
    })
   }



   let datableValues = rawFiles;
   if(searchTerm)
   {
       datableValues = rawFiles.filter((entity) => {
           const termsToCheck = [
               entity.id,
               entity.author,
               entity.name
           ];

           const joinTerm = _.join(termsToCheck, ' ').toLocaleLowerCase();
           return joinTerm.includes(searchTerm);
       });
   }

   

   return <>
   
   <TableWithCrudComponent 
       entities={rawFiles} 
       columns={columns}
       loadingForm={loadingForm}
       loadingList={rawFiles.length == 0 && loadingList}
       addDefaultActionsColumn={false}
       hideIdColumn={showCheckbox}
       defaultSortFieldId='dateCreated'
       defaultSortFieldDirection='desc'
       onClickCreateButton={haveEditPermission ? () => setShowCreateAuditModal(true) : undefined}
       translations={{
           tableTitle: t("Audits de la carte"),
           noEntityText: t("Aucun audit disponible"),
           createButtonText: t("Créer un audit"),
           deleteText: (entity) => (t("Voulez-vous supprimer l'audit ?")),
           deleteTitle: (entity) => (t("Supprimer l'audit ") + entity.name),
       }}
       fieldSearchable={(entity) => [
           entity.id,
           entity.author,
           entity.name
       ]}
   />

   {showCreateAuditModal && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateAuditModal(false)}>
               <Modal.Header closeButton>
                   <Modal.Title>{t("Créer un audit")}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <AuditUploadRawFileForm onCancel={() => setShowCreateAuditModal(false)} 
                                           initialValues={{mapId: mapId ?? ""} as AuditRawFileDetails}
                                           allMaps={allMaps}
                                           onSubmit={onCreateEntity} 
                                           loading={loadingForm}/>
               </Modal.Body>
           </Modal>}


       {selectedEntityToDelete && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedEntityToDelete(undefined)}>
               <Modal.Header closeButton>
                   <Modal.Title>{t("Supprimer l'audit") + " " + selectedEntityToDelete.name}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <DeleteConfirmForm onCancel={() => setSelectedEntityToDelete(undefined)} 
                                       onSubmit={() => onDelete(selectedEntityToDelete)} 
                                       text={t("Êtes vous sûre de vouloir supprimer cet audit ?")}
                                       loading={loadingForm}/>
               </Modal.Body>
           </Modal>}
   
   </>;
}
export default AuditsList