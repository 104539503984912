import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { ROUTES } from 'resources/routes-constants';

/**
 * Component to restrict access to a specific customer.
 * @param {React.ReactNode} children - The child components to render if the customer matches.
 * @param {string} customerName - The name of the customer to check against.
 */
const OnlyForCustomer: React.FC<{ children: React.ReactNode, customerName: string }> = ({ children, customerName }) => {
    const location = useLocation();
    const { currentTenant } = useAppSelector(state => state.userProfil);

    // Check if the current tenant matches the specified customer name
    const isCustomer = currentTenant && currentTenant.name.toLowerCase() === customerName.toLowerCase();

    // If not the specified customer, navigate to the dashboard
    if (!isCustomer) {
        return <Navigate to={ROUTES.DASHBOARD} state={{ from: location }} replace />;
    }

    // Render children if the customer matches
    return <>{children}</>;
};

export default OnlyForCustomer;
