import i18next from "i18next";
import { toast } from "react-toastify";
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import { middlewareBatchingActions } from "../middlewareBatchingSlice";
import IBatchingRawFileCriteria from "../../models/IBatchingRawFileCriteria";

/**
 * get batching raw files from middleware with criteria
 * @param baseUrl 
 * @param mapId 
 * @param setLoading 
 * @returns 
 */
export const loadBatchingRawFileThrunk = (
        baseUrl: string, 
        mapId: string, 
        criteria: IBatchingRawFileCriteria,
        setLoading = true): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        const tenant = getState().userProfil.currentTenant?.tenant
        if(setLoading)
        {
          dispatch(middlewareBatchingActions.setLoadingList(true));
        }

        dispatch(middlewareBatchingActions.setBatchingSearchCriteria(criteria));

        const batchingFileResult = await apiMiddlewareDefinition(baseUrl, tenant ?? "")
                                  .batching.searchBatchingRawFiles(mapId, {
                                    mapId: mapId,
                                    pageSize: criteria.pageSize ?? 10,
                                    page: criteria.currentPage ?? 1,
                                    sortColumn: criteria.sortColumn,
                                    sortDirection: criteria.sortDirection
                                  });

       dispatch(middlewareBatchingActions.setBatchingRawFiles(batchingFileResult.data));

      } catch (error: any) {

        if (!error.status || error.status != 401) {
          //toast.error(i18next.t("Erreur lors du chargement des fichiers de batching"));
          console.error("Erreur lors du chargement des fichiers de batching")
          dispatch(middlewareBatchingActions.setBatchingRawFiles(undefined));
        }
      } finally {
        // Set loading state back to false after the operation completes
        if(setLoading)
        {
          dispatch(middlewareBatchingActions.setLoadingList(false));
        }
       
      }
    };
  };