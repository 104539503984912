import {chronoActions} from '../chronodriveSlice';
import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";


export const loadAllSiteSettings = (): AppThunk => {
return async (dispatch, getState) => {
  try {

    const tenant = getState().userProfil.currentTenant?.tenant?? ""
    const baseUrl = getState().middlewareApis.middlewareSelected?.baseUrl ?? "";
    const currentMap = getState().map.currentMap;
    const mapId = currentMap?.reference ?? currentMap?.id ?? "";

    dispatch(chronoActions.setLoadingGetSettings(true));

    
    const searchResult = await apiChronodriveDefinition(baseUrl , tenant , mapId)
                              .settings.getAllSettingsBySiteId();

    // Set the sector codes in the store
    dispatch(chronoActions.setSectorCodes(searchResult.data));

  } catch (error: any) {

    if (!error.status || error.status != 401) {
      console.error("[loadAllSiteSettings] Error while loading site settings", error);
    }
  } finally {
    // Set loading state back to false after the operation completes
    dispatch(chronoActions.setLoadingGetSettings(false));
  }
};
};


