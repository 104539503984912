import { ChronodriveRoutes } from "pages/Client/Middleware/Chronodrive/ChronodriveRoutes";
import React from "react";
import { useRoutes, BrowserRouter } from "react-router-dom";
import { AdminRoutes } from "routes/AdminRoutes";
import { ClientRoutes } from "routes/ClientRoutes";
import { DemoRoutes } from "routes/DemoRoutes";
import { PublicRoutes } from "routes/PublicRoutes";


const AppRouter: React.FC = () => {

    const routes = [
        ...PublicRoutes,
        ...AdminRoutes,
        ...ClientRoutes,
        ...ChronodriveRoutes,
        ...DemoRoutes
    ];


  const element = useRoutes(routes);
  return element;
};

const RouterWrapper: React.FC = () => {
  return (
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  );
};

export default RouterWrapper;