import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';

dayjs.locale('fr');

interface RelativeDateTimeProps {
    date: string;
}

const RelativeDateTime: React.FC<RelativeDateTimeProps> = ({ date }) => {
    const { t } = useTranslation(nameOf({RelativeDateTime}), { useSuspense: false});
    const dateObj = dayjs(date);
    const now = dayjs();

    let displayDate;
    const diffMinutes = Math.max(0, dateObj.diff(now, 'minute'));

    if (diffMinutes < 60 && diffMinutes > 0) {
        displayDate = t('Dans {{count}} min', { count: diffMinutes });
    } else if (dateObj.isSame(now, 'day')) {
        //displayDate = t("Aujourdh'ui à {{time}}", { time: dateObj.format('HH[h]mm') });
        displayDate = dateObj.format('HH[h]mm');
    } else if (dateObj.isSame(now.add(1, 'day'), 'day')) {
        displayDate = t('Demain à {{time}}', { time: dateObj.format('HH[h]mm') });
    } else if (dateObj.isSame(now.subtract(1, 'day'), 'day')) {
        displayDate = t('Hier à {{time}}', { time: dateObj.format('HH[h]mm') });
    } else {
        displayDate = dateObj.format('DD/MM/YYYY HH:mm');
    }

    return <span title={dateObj.format('DD/MM/YYYY HH:mm')}>{displayDate}</span>;
};

export default RelativeDateTime;
