import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import uploadFileToStorage from "utility/file/uploadFileToStorage";
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import { BatchingRawFileCreateBaseBatchingRawFileColumnsBinding, BatchingRawFileDetails } from "backend/ApiMiddlewareDefinition/data-contracts";
import { middlewareBatchingActions } from "../middlewareBatchingSlice";

/**
 * Thunk action creator for creating and uploading a batching raw file
 * @param baseUrl - Base url of the middleware
 * @param tenant - Tenant to use for middleware api calls
 * @param mapId - The ID of the map
 * @param data - The data for creating the audit raw file
 * @param csvFile - The CSV file to upload
 * @param callbacks - Callbacks for success and error handling
 * @returns An AppThunk function
 */
export const createAndUploadBatchingRawFileThunk = (
    baseUrl: string,
    tenant: string,
    mapId: string,
    data: BatchingRawFileCreateBaseBatchingRawFileColumnsBinding,
    csvFile: File,
    callbacks: ThrunkApiCallBacks<BatchingRawFileDetails>
  ): AppThunk => {
    return async (dispatch, getState) => {
      try {
        // Set loading state to true
        dispatch(middlewareBatchingActions.setLoadingForm(true));
        
        // Create audit raw file
        const result = await apiMiddlewareDefinition(baseUrl, tenant).batching.createBatchingRawFile(mapId, data);
  
        // Check if creation was successful
        if (!result.ok) {
          // Dispatch action to set loading state back to false
          dispatch(middlewareBatchingActions.setLoadingForm(false));
          // Call onError callback with API error response
          callbacks.onError(result.error as apiErrorModel);
          return;
        }
  
        // Upload CSV file to storage
        const uploadFileResult = await uploadFileToStorage(result.data.temporaryUploadUrlDataCsv ?? "", csvFile);
  

        // Check if upload was successful
        if (!uploadFileResult) {

          // Dispatch action to set loading state back to false
          dispatch(middlewareBatchingActions.setLoadingForm(false));
          // Call onError callback with error message
          callbacks.onError({errors:{
                "csvFile": [`Erreur lors de l'upload des fichiers sur le storage. La configuration crée est corrompue, veuillez la supprimer et la recréer.`]
            }} as apiErrorModel);
          return;
        }
  
        const finalResult = await apiMiddlewareDefinition(baseUrl, tenant).batching.setBatchingRawFileUploaded(mapId, result.data.id)

  
        // Call onSuccess callback with the created raw file
        callbacks.onSuccess(finalResult.data);
      } catch (error: any) {
        // Handle any errors that occur during the async operation
        // Display error notification or perform error handling as needed
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(middlewareBatchingActions.setLoadingForm(false));
      }
    };
  };
