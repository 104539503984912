import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import { Offcanvas } from 'react-bootstrap';
import "./BatchingFilesImport.scss"
import _ from 'lodash';
import BatchingFilesList from './components/BatchingFilesList';
import BatchingFileDetails from './components/BatchingFileDetails';
import { middlewareBatchingActions } from 'pages/Client/Middleware/Batching/reducer/middlewareBatchingSlice';

const BatchingFilesImport: React.FC = () => {
    const { t } = useTranslation(nameOf({BatchingFilesImport}), { useSuspense: false});
    const dispatch = useAppDispatch();

    const {
        batchingFileSelected
    } = useAppSelector(state => state.middlewareBatching);


    useEffect(() => {
        document.title = t("Fichiers de regroupement");

    }, [])

    return (
        <div> 
            <BatchingFilesList />
            <Offcanvas className="offcanvas-batching-details" show={!!batchingFileSelected} onHide={() => dispatch(middlewareBatchingActions.setBatchingFileSelected(undefined))} >
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{batchingFileSelected?.name}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {batchingFileSelected && <BatchingFileDetails batchingFile={batchingFileSelected}/>}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default BatchingFilesImport
