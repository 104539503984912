// TODO a supprimer car a changer avec ApiChronodriveDefinition provenant du dossier backend
export class ApiChronodriveDefinition {
    private baseUrl: string;
    private token: string;
    private siteId: string;

    constructor(baseUrl: string, token: string, siteId: string) {
        this.baseUrl = baseUrl;
        this.token = token;
        this.siteId = siteId;
    }

    private getHeaders(): Headers {
        return new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`,
            'siteId': this.siteId
        });
    }

    /*async get(endpoint: string): Promise<any> {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
            method: 'GET',
            headers: this.getHeaders()
        });
        return response.json();
    }*/

    async put(id: string, data: any): Promise<Response> {
        const response = await fetch(`${this.baseUrl}/v1/pools/${id}`, {
            method: 'PUT',
            headers: this.getHeaders(),
            body: JSON.stringify(data)
        });
        return response;
    }

    /*async patch(endpoint: string, data: any): Promise<any> {
        const response = await fetch(`${this.baseUrl}${endpoint}`, {
            method: 'PATCH',
            headers: this.getHeaders(),
            body: JSON.stringify(data)
        });
        return response.json();
    }*/
}