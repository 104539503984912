import _ from "lodash";
import { AppThunk } from "store/store";
import { ThrunkApiCallBacks } from "models/ThrunkApiCallbacks";
import apiErrorModel from "backend/utils/apiErrorModel";
import { middlewarePickingActions } from "../../../../../../store/reducers/MiddlewarePicking/middlewarePickingSlice";
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";




/**
 * Thunk action creator for deleting an audit raw file
 * @param baseUrl - Base url of the middleware
 * @param tenant - Tenant to use for middleware api calls
 * @param mapId - The ID of the map
 * @param fileId - The ID of the file to delete
 * @param callbacks - Callbacks for success and error handling
 * @returns An AppThunk function
 */
export const deleteBatchingRawFileThunk = (
    baseUrl: string,
    tenant: string,
    mapId: string,
    fileId: string,
    callbacks: ThrunkApiCallBacks<boolean>
): AppThunk => {
    return async (dispatch, getState) => {
        
        // Set loading state to true
        dispatch(middlewarePickingActions.setLoadingForm(true));

        // Delete audit raw file
        const result = await apiMiddlewareDefinition(baseUrl, tenant).batching.deleteBatchingRawFile(mapId, fileId);
        if (!result.ok) {
            // Call onError callback with API error response
            callbacks.onError(result.error as apiErrorModel);
        } else {
            // Call onSuccess callback with true indicating successful deletion
            callbacks.onSuccess(true);
        }
        
        // Set loading state back to false after the operation completes
        dispatch(middlewarePickingActions.setLoadingForm(false));
    };
};
