import {chronoActions} from '../chronodriveSlice';
import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";


export const loadStatisticsThrunk = (): AppThunk => {
return async (dispatch, getState) => {
  try {
    const tenant = getState().userProfil.currentTenant?.tenant?? ""
    const baseUrl = getState().middlewareApis.middlewareSelected?.baseUrl ?? "";
    const currentMap = getState().map.currentMap;
    const mapId = currentMap?.reference ?? currentMap?.id ?? "";

    const {
        dateFrom,
        dateTo,
    } = getState().menuDatesSelector;

    dispatch(chronoActions.setLoadingStatistics(true));

    const formatDate = (date: Date) => {
      const pad = (num: number) => num.toString().padStart(2, '0');
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
    };

    const searchResult = await apiChronodriveDefinition(baseUrl, tenant, mapId)
                  .statistic.getStatistics({
                    from: formatDate(dateFrom),
                    to: formatDate(dateTo)
                  });

    // Set the sector codes in the store
    dispatch(chronoActions.setStatistics(searchResult.data));

  } catch (error: any) {
    if (!error.status || error.status != 401) {
      console.error("[loadStatisticsThrunk] Error while loading statistics", error);
    }
  } finally {
    // Set loading state back to false after the operation completes
    dispatch(chronoActions.setLoadingStatistics(false));
  }
};
};


