import RequireAuth from "components/Routes/RequireAuth";
import RequireNotAuth from "components/Routes/RequireNotAuth";
import Login from "pages/Login";
import NotFoundPage from "pages/NotFoundPage";
import { Navigate, RouteObject } from "react-router-dom";
import { ROUTES } from "resources/routes-constants";



export const PublicRoutes: RouteObject[] = [
    { path: "*", element: <RequireAuth><NotFoundPage /></RequireAuth> },
    { path: "/", element: <Navigate to={ROUTES.DASHBOARD} replace /> },
    { path: ROUTES.LOGIN, element: <RequireNotAuth><Login /></RequireNotAuth> },
];
