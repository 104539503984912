import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import React from 'react'
import { Bar } from 'react-chartjs-2';




interface OwnProps {
    labels: string[];
    datasets: IDatasetModel[];
    /**Show pourcentage in the chart */
    showPourcentage?: boolean;
 }


/**
 * A bar chart component that displays data as bars.
 * @name BarStackedChart
 * @component
 * @param {OwnProps} props - Component props
 * @param {string[]} props.labels - Array of labels for the x-axis of the chart
 * @param {IDatasetModel[]} props.datasets - Array of datasets for the chart
 * @param {boolean} [props.showPourcentage=false] - Indicates if the chart should display the percentage of each bar
 * @return {JSX.Element} The bar chart component
 */
const BarStackedChart: React.FC<OwnProps> = ({labels, datasets, showPourcentage = false}) => {

    const options = {
        tooltips: {
            position: 'nearest',
            displayColors: false
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: { 
          x: {
            stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        plugins: {
          legend: {
            position: 'bottom' as const,
          },
          title: {
            display: false,
            // text: 'Chart.js Line Chart',
          },
          datalabels:{
            formatter: (value, ctx) => {
                
              if(!showPourcentage) return "";

              let sum = 0;
              const dataArr = ctx.chart.data.datasets[0].data as number[];
              dataArr.map(data => {
                  sum += data;
              });
              const percentage = (value*100 / sum).toFixed(2)+"%";
              return percentage;

          
            },
            color: '#fff',
          },
        },
      } as ChartOptions<"bar">;

    const data = {
        labels,
        datasets: datasets.map(dataset => {
            return {
                data: dataset.data,
                label: dataset.label,
                backgroundColor: dataset.color,
                borderColor: dataset.color,
                fillColor: dataset.color,
                borderWidth: 5,
                pointRadius: 3,
                pointHoverRadius: 4,
                pointHoverBorderColor: dataset.color,
                pointHoverBackgroundColor: dataset.color,
                pointHitRadius: 20,
                fill: false,
                barPercentage: 1.0
            }
        })
      } as ChartData<'bar', number[]>;

    return <div style={{height: '300px'}}>
        <Bar options={options} data={data} />
    </div>
    

}

export default BarStackedChart