import React from 'react';
import BarChart from 'components/Charts/BarChart';
import appColors from 'resources/appColors';
import { useTranslation } from 'react-i18next';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import { StatisticsDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import nameOf from 'utility/nameOf';

const PoolsChart: React.FC<{ labels: string[], statistics: StatisticsDetails}> = ({ labels, statistics }) => {
    const { t } = useTranslation(nameOf({PoolsChart}), { useSuspense: false});

    const datasetTotalPoolsCreated = {
        data: Object.values(statistics?.poolsCreated ?? {}).map(nbPools => nbPools ?? 0),
        label: t("Création de pools"),
        color: appColors.blue,
    } as IDatasetModel;

    const datasetTotalPoolsDistributed = {
        data: Object.values(statistics?.poolsDistributed ?? {}).map(nbPools => nbPools ?? 0),
        label: t("Pools distribués"),
        color: appColors.green,
    } as IDatasetModel;

    const datasetTotalPoolsOverdue = {
        data: Object.values(statistics?.poolsOverdue ?? {}).map(nbPools => nbPools ?? 0),
        label: t("Pools dépassés"),
        color: appColors.red,
    } as IDatasetModel;

    const datasetTotalPoolsOptimizedNotDistributed = {
        data: Object.values(statistics?.poolsOptimizedNotDistributed ?? {}).map(nbPools => nbPools ?? 0),
        label: t("Pools optimisé non distribués"),
        color: appColors.orange,
    } as IDatasetModel;

    return (
        <div className='table-component'>
            <header className='section-header'><h5 className='text-uppercase'>{t("Gestion des pools (Date livraison)")}</h5></header>
            <BarChart labels={labels} datasets={[datasetTotalPoolsCreated, datasetTotalPoolsDistributed, datasetTotalPoolsOptimizedNotDistributed, datasetTotalPoolsOverdue]} />
        </div>
    );
}

export default PoolsChart;
