import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import "./BatchingDashboard.scss"
import _ from 'lodash';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';

const BatchingDashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({BatchingDashboard}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const location = useLocation();
    const {
        currentTenant,
      } = useAppSelector(state => state.userProfil);

    const isAuchanCustomer = currentTenant && currentTenant.name.toLowerCase() === "auchan";
    const isChronodriveCustomer = currentTenant && currentTenant.name.toLowerCase() === "chronodrive";

    useEffect(() => {
        document.title = t("Dashboard Batching");

    }, [])

    
    if(isChronodriveCustomer)
    {
            return <Navigate to={ROUTES.BATCHING_CHRONO_STATISTICS} state={{ from: location }} replace />;
    }

    return <Navigate to={ROUTES.BATCHING_FILES_IMPORT} state={{ from: location }} replace />;
}

export default BatchingDashboard
