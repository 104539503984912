/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApiErrorsResponse {
    errors?: Record<string, string[]>
}

/** Average, min, and max statistics result. */
export interface AverageMinMaxStatisticResult {
    /** @format double */
    average?: number
    /** @format double */
    min?: number
    /** @format double */
    max?: number
}

/** Represents the load status of the site. */
export enum ChargeStatusEnum {
    URGENT = 'URGENT',
    WARNING = 'WARNING',
    NORMAL = 'NORMAL'
}

/** Distribution status of the pool */
export enum DistributionEnum {
    WAITING = 'WAITING',
    DISTRIBUTED = 'DISTRIBUTED',
    OVERDUE = 'OVERDUE',
    NOT_COMPLIANT = 'NOT_COMPLIANT'
}

export interface HomeStatusResult {
    build: string | null
}

export interface MiddlewareStatusResult {
    realtimeOnline?: boolean
    buildDate?: string | null
}

export interface MissionOptimizedDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    siteId: string
    /** @minLength 1 */
    sectorCode: string
    /**
     * Mission ID generate by batching optimization (From runner)
     * @minLength 1
     */
    missionFnoId: string
    /**
     * Runner result id for versionning purpose
     * @minLength 1
     */
    runnerResultId: string
    /** Is already sent to client */
    isDistributed: boolean
    /**
     * Date limit for preparation
     * @format date-time
     */
    dateLimitForPreparation: string
    /** All pool ids that are part of this mission */
    poolIds: number[]
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** @format int32 */
    totalProducts: number
}

export interface MissionOptimizedDetailsPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: MissionOptimizedDetails[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

/** Indiquate the completion of the optimization */
export enum OptimizationCompletionEnum {
    WAITING_OPTIMIZATION = 'WAITING_OPTIMIZATION',
    COMPLET = 'COMPLET',
    PARTIAL = 'PARTIAL',
    IGNORE = 'IGNORE'
}

export interface PoolDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    siteId: string
    /** @minLength 1 */
    sectorCode: string
    /**
     * Id chronodrive sous la forme siteId + ‘-’ + poolId
     * @minLength 1
     */
    chronodriveId: string
    /**
     * Order number
     * @format int32
     */
    orderId: number
    /**
     * Pool ID
     * @format int32
     */
    poolId: number
    status: PoolStatusEnum
    /** Distribution status of the pool */
    distributionStatus: DistributionEnum
    /** Represents the load status of the site. */
    distributionTypeStatus: ChargeStatusEnum
    /** Indiquate the completion of the optimization */
    optimizationCompletion: OptimizationCompletionEnum
    /**
     * Requested truck departure time
     * @format date-time
     */
    departureTime: string
    /**
     * time when the pool need to be ready:
     * It depend on the Departure and the number of product(* TpsArt) and
     * the TpsLimite (security time).
     * @format date-time
     */
    readyDateTime: string
    /** Product list */
    pickings: ProductDetails[]
    /**
     * Date of the distribution to the client (GET pool is called by client for next poolIds to pick)
     * @format date-time
     */
    distributionDate?: string | null
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
}

export interface PoolDetailsPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: PoolDetails[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

export interface PoolDetailsWithProductsParams {
    /**
     * Store code
     * @minLength 1
     */
    siteId: string
    /**
     * Preparation sector code (e.g., PGC 3500, Frais)
     * @minLength 1
     */
    sectorCode: string
    /**
     * Order number
     * @format int32
     */
    orderId: number
    /**
     * Pool ID
     * @format int32
     */
    poolId: number
    status: PoolStatusEnum
    /**
     * Requested truck departure time
     * @minLength 1
     */
    departureTime: string
    pickings: ProductDetails[]
}

export interface PoolOptimizationResult {
    pools?: number[] | null
}

export enum PoolStatusEnum {
    AVAILABLE = 'AVAILABLE',
    UNAVAILABLE = 'UNAVAILABLE'
}

export interface PoolStatusUpdateParams {
    status: PoolStatusEnum
}

export interface ProductDetails {
    /** @minLength 1 */
    storageArea: string
    /** @format int32 */
    shelfId: number
    /** @format int32 */
    columnId: number
    /** @format int32 */
    quantity: number
    /**
     * Location code in the format StorageArea.ShelfId.ColumnId
     * @minLength 1
     */
    locationCode: string
}

export interface RequestStageDetails {
    /** @format date-time */
    date: string
    /** @minLength 1 */
    stageType: string
    /** @minLength 1 */
    message: string
    /** @format double */
    duration: number
    isError: boolean
}

export interface RunnerResultDetails {
    /** @minLength 1 */
    id: string
    /** @minLength 1 */
    siteId: string
    /** @minLength 1 */
    sectorCode: string
    /** @format date-time */
    date: string
    /**
     * Total duration of the optimization
     * @format date-span
     */
    processDuration: string
    /** @format double */
    processDurationMs: number
    /**
     * Json params sent to runner
     * @minLength 1
     */
    jsonRunnerRequest: string
    /** Mission generate from runner (mission FnO with poolIds) */
    results: Record<string, string[]>
    supportsNotOptimized: Record<string, string[]>
    /** Location codes that are not found in the matrix and the number used for current optimization */
    missingLocationCodes: Record<string, number>
    logs: RequestStageDetails[]
    /** @format int32 */
    nbPools: number
    /** @format int32 */
    nbProducts: number
    success: boolean
    /** @format date-time */
    dateCreated: string
    /** @format date-time */
    dateUpdated: string
    /** All pool ids that are part of this batch */
    poolIds: number[]
}

export interface RunnerResultDetailsPagedListResult {
    /** @format int32 */
    currentPage?: number
    /** @format int32 */
    pageSize?: number
    sortColumn?: string | null
    sortDirection?: string | null
    items?: RunnerResultDetails[] | null
    /** @format int32 */
    totalCount?: number
    /** @format int32 */
    totalPages?: number
}

export interface SiteSettingDetails {
    /** @minLength 1 */
    id: string
    /**
     * [CodeSite] Store's code
     * CODE_SITE
     * @minLength 1
     */
    siteId: string
    /**
     * [CodeSecteur] Preparation sector code (e.g., PGC 3500, Frais)
     * CODE_SECTEUR
     * @minLength 1
     */
    sectorCode: string
    /**
     * Average picking time per item (in seconds)
     * TPS_PAR_ART
     * @format int32
     */
    averagePickingTimeByProductSec: number
    /**
     * minimum number box
     * NB_COLIS_MIN
     * @format int32
     */
    nbMinSupportBatch: number
    /**
     * mmaximum number box
     * NB_COLIS_MAX
     * @format int32
     */
    nbMaxSupportBatch: number
    /**
     * Minimum number of boxes
     * NB_CDE_MIN
     * @format int32
     */
    nbMinCommandBatch: number
    /**
     * Maximum number of orders
     * NB_CDE_MAX
     * @format int32
     */
    nbMaxCommandBatch: number
    /**
     * Minimum number of items in the multi-pool
     * NB_ART_MIN
     * @format int32
     */
    nbMinProduitBatch: number
    /**
     * Maximum number of items in the multi-pool
     * NB_ART_MAX
     * @format int32
     */
    nbMaxProduitBatch: number
    /**
     * Maximum number of items in one pool
     * MAX_ART_CDE
     * @format int32
     */
    nbMaxArticleByPool: number
    /**
     * Maximum number of article by box
     * NB_ART_CAISSE
     * @format int32
     */
    nbMaxArticleByBox: number
    /**
     * Time beyond which a preparation (before the requested delivery date) is no longer eligible for multi-prep
     * TPS_LIMITE to validate
     * @format int32
     */
    securityTimeSeconds: number
    /**
     * Maximum number of pool per period.
     * After this number, the urgence mode is activated
     * @format int32
     */
    maxPoolPerPeriod: number
    /**
     * Period in minute where the maximum number of pool is calculated.
     * If the number of pool is greater than MaxPoolPerPeriod for this period, the urgence mode is activated
     * @format int32
     */
    periodInMinute: number
    /**
     * interval max in hour taken for pool optimization
     * @format int32
     */
    maxHourPoolTakenForLimitedOptimization: number
    /**
     * Hour when we switch from limited optimization to full optimization
     * @format int32
     */
    hourChangeBehaviour?: number | null
    /**
     * Start of the sector. Use for calculation
     * @minLength 1
     */
    startLocationCode: string
    /**
     * End of the sector. Use for calculation
     * @minLength 1
     */
    endLocationCode: string
}

export interface SiteSettingUpdate {
    /**
     * [CodeSecteur] Preparation sector code (e.g., PGC 3500, Frais)
     * CODE_SECTEUR
     * @minLength 1
     */
    sectorCode: string
    /**
     * Average picking time per item (in seconds)
     * NB_ART_CAISSE
     * @format int32
     */
    averagePickingTimeByProductSec: number
    /**
     * minimum number box
     * NB_COLIS_MIN
     * @format int32
     */
    nbMinSupportBatch: number
    /**
     * mmaximum number box
     * NB_COLIS_MAX
     * @format int32
     */
    nbMaxSupportBatch: number
    /**
     * Minimum number of boxes
     * NB_CDE_MIN
     * @format int32
     */
    nbMinCommandBatch: number
    /**
     * Maximum number of orders
     * NB_CDE_MAX
     * @format int32
     */
    nbMaxCommandBatch: number
    /**
     * Minimum number of items in the multi-pool
     * NB_ART_MIN
     * @format int32
     */
    nbMinProduitBatch: number
    /**
     * Maximum number of items in the multi-pool
     * NB_ART_MAX
     * @format int32
     */
    nbMaxProduitBatch: number
    /**
     * Maximum number of items in one pool
     * MAX_ART_CDE
     * @format int32
     */
    nbMaxArticleByPool: number
    /**
     * Maximum number of article by box
     * NB_ART_CAISSE
     * @format int32
     */
    nbMaxArticleByBox: number
    /**
     * Time beyond which a preparation (before the requested delivery date) is no longer eligible for multi-prep
     * TPS_LIMITE to validate
     * @format int32
     */
    securityTimeSeconds: number
    /**
     * Maximum number of pool per period.
     * After this number, the urgence mode is activated
     * @format int32
     */
    maxPoolPerPeriod: number
    /**
     * Period in minute where the maximum number of pool is calculated.
     * If the number of pool is greater than MaxPoolPerPeriod for this period, the urgence mode is activated
     * @format int32
     */
    periodInMinute: number
    /**
     * interval max in hour taken for pool optimization
     * @format int32
     */
    maxHourPoolTakenForLimitedOptimization: number
    /**
     * Hour when we switch from limited optimization to full optimization
     * @format int32
     */
    hourChangeBehaviour?: number | null
    /**
     * Start of the sector. Use for calculation
     * @minLength 1
     */
    startLocationCode: string
    /**
     * End of the sector. Use for calculation
     * @minLength 1
     */
    endLocationCode: string
}

export interface StatisticsDetails {
    /** @minLength 1 */
    siteId: string
    sectorCode?: string | null
    /** @format date-time */
    from: string
    /** @format date-time */
    to: string
    /** Total pools created by day */
    poolsCreated: Record<string, number>
    poolsDistributed: Record<string, number>
    poolsOverdue: Record<string, number>
    /** Pools optimized but not distributed to the client */
    poolsOptimizedNotDistributed: Record<string, number>
    nbProducts: Record<string, number>
    /** Number of products distributed by sector code */
    nbProductsBySectorDistributed: Record<string, Record<string, number>>
    /** Number of products distributed by distribution type (NORMAL and URGENT) */
    nbProductsByDistributionType: Record<string, Record<string, number>>
    missiongLocationCodes: Record<string, number>
    /** Number of runner calls by day and by sector code */
    nbRunnerCallsBySector: Record<string, Record<string, number>>
    /** Average process duration per day */
    averageProcessDurationPerDay: Record<string, AverageMinMaxStatisticResult>
    averagePoolByMissionPerDay: Record<string, AverageMinMaxStatisticResult>
    /** Average pools by sector by day */
    averagePoolBySectorPerDay: Record<string, Record<string, number>>
}
