import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DistributionEnum, MissionOptimizedDetailsPagedListResult, PoolDetails, PoolDetailsPagedListResult, PoolStatusEnum, RunnerResultDetailsPagedListResult, SiteSettingDetails, StatisticsDetails } from "backend/ApiChronodriveDefinition/data-contracts";
import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { mapActions } from "../Map/mapSlice";
import IPoolSearchCriteria from "./models/IPoolSearchCriteria";
import IRunnerResultSearchCriteria from "./models/IRunnerResultSearchCriteria";
import IMissionOptimizedSearchCriteria from "./models/IMissionOptimizedSearchCriteria";

export interface IChronoStore {

  /** Pools */
  poolSearchResult?: PoolDetailsPagedListResult;
  poolSearchCriteria: IPoolSearchCriteria;
  loadingPools: boolean;

  /**Site settings */
  loadingGetSiteSettings: boolean;
  loadingFormSiteSettings: boolean;
  siteSettings: SiteSettingDetails[];

  /**Statistics */
  loadingStatistics: boolean;
  statistics?: StatisticsDetails;

  /**Runner results */
  loadingRunnerResults: boolean;
  runnerResults?: RunnerResultDetailsPagedListResult;
  runnerResultSearchCriteria: IRunnerResultSearchCriteria;

    /**Mission optimized results */
    loadingMissionOptimizedResults: boolean;
    missionOptimized?: MissionOptimizedDetailsPagedListResult;
    missionOptimizedSearchCriteria: IMissionOptimizedSearchCriteria;
}

const initialState: IChronoStore = {
  loadingPools: false,
  poolSearchCriteria:{
    currentPage: 1,
    pageSize: 10,
    sector: undefined,
    distributionEnum: undefined,
    status: PoolStatusEnum.AVAILABLE,
    sortColumn:"ReadyDateTime",
    sortDirection:"desc"
  },
  poolSearchResult: undefined,

  loadingGetSiteSettings: false,
  loadingFormSiteSettings: false,
  siteSettings: [],

  loadingStatistics: false,
  statistics: undefined,


  loadingRunnerResults: false,
  runnerResults: undefined,
  runnerResultSearchCriteria:{
    currentPage: 1,
    pageSize: 10,
    sector: undefined,
    sortColumn:"DateCreated",
    sortDirection:"desc"
  },

  loadingMissionOptimizedResults: false,
  missionOptimized: undefined,
  missionOptimizedSearchCriteria:{
    currentPage: 1,
    pageSize: 10,
    batchId: undefined,
    poolId: undefined,
    sector: undefined,
    sortColumn:"DateCreated",
    sortDirection:"desc"
  },
};

const chronoSlice = createSlice({
  name: 'chrono',
  initialState,
  reducers: {
    setLoadingPools: (state, action: PayloadAction<boolean>) => {
      state.loadingPools = action.payload;
    },
    setPoolSearchResult: (state, action: PayloadAction<PoolDetailsPagedListResult>) => {
      state.poolSearchResult = action.payload;
    },
    setPoolSearchCriteria: (state, action: PayloadAction<IPoolSearchCriteria>) => {
      state.poolSearchCriteria = action.payload;
    },

    setLoadingGetSettings: (state, action: PayloadAction<boolean>) => {
      state.loadingGetSiteSettings = action.payload;
    },

    setLoadingFormSettings: (state, action: PayloadAction<boolean>) => {
      state.loadingFormSiteSettings = action.payload;
    },

    addOrUpdateSetting: (state, action: PayloadAction<SiteSettingDetails>) => {
      const index = state.siteSettings.findIndex(x => x.id === action.payload.id);
      if(index === -1){
        state.siteSettings.push(action.payload);
      }else{
        state.siteSettings[index] = action.payload;
      }
    },

    removeSettingById: (state, action: PayloadAction<string>) => {
      state.siteSettings = state.siteSettings.filter(x => x.id !== action.payload);
    },

    setSectorCodes: (state, action: PayloadAction<SiteSettingDetails[]>) => {
      state.siteSettings = action.payload;
    },

    setLoadingStatistics: (state, action: PayloadAction<boolean>) => {
      state.loadingStatistics = action.payload;
    },
    setStatistics: (state, action: PayloadAction<StatisticsDetails>) => {
      state.statistics = action.payload;
    },

    setLoadingRunnerResults: (state, action: PayloadAction<boolean>) => {
      state.loadingRunnerResults = action.payload;
    },
    setRunnerResults: (state, action: PayloadAction<RunnerResultDetailsPagedListResult>) => {
      state.runnerResults = action.payload;
    },
    setRunnerResultSearchCriteria: (state, action: PayloadAction<IRunnerResultSearchCriteria>) => {
      state.runnerResultSearchCriteria = action.payload;
    },

    setLoadingMissionOptimizedResults: (state, action: PayloadAction<boolean>) => {
      state.loadingMissionOptimizedResults = action.payload;
    },
    setMissionOptimizedResults: (state, action: PayloadAction<MissionOptimizedDetailsPagedListResult>) => {
      state.missionOptimized = action.payload;
    },
    setMissionOptimizedSearchCriteria: (state, action: PayloadAction<IMissionOptimizedSearchCriteria>) => {
      state.missionOptimizedSearchCriteria = action.payload;
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(userProfilActions.setLogout, (state) => {
      state.loadingPools = false;
      state.poolSearchResult = undefined;
    }),

    // if map change, reset all data
    builder.addCase(mapActions.setCurrentMap, (state) => {
      state.poolSearchResult = undefined;
    })
  }
});

export const { actions: chronoActions, reducer: chronoReducer } = chronoSlice;