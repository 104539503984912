import { RouteObject } from 'react-router-dom';
import DemoPicking from 'pages/Demos/DemoPicking/DemoPicking';
import DemoNotion from 'pages/Demos/DemoNotion';
import DemoHeatmapSAP from 'pages/Demos/HeatmapSAP/DemoHeatmapSAP';
import SAPOrdersList from 'pages/Demos/SAPOrdersList';
import TimcodeMapTagManager from 'pages/Demos/Timcode/TimcodeMapTagManager';
import TimcodeFinalResultSuperU from 'pages/Demos/Timcode/TimcodeFinalResultSuperU';
import TimcodeFinalResultTimcode from 'pages/Demos/Timcode/TimcodeFinalResultTimcode';
import TimcodeBatching from 'pages/Demos/Timcode/TimcodeBatching';
import { ROUTES } from 'resources/routes-constants';
import RequireAuth from 'components/Routes/RequireAuth';

export const DemoRoutes: RouteObject[] = [
    { path: ROUTES.DEMO.TIMCODE.TAG_MANAGER, element: <RequireAuth><TimcodeMapTagManager /></RequireAuth> },
    { path: ROUTES.DEMO.TIMCODE.BATCHING_SUPER_U, element: <RequireAuth><TimcodeFinalResultSuperU /></RequireAuth> },
    { path: ROUTES.DEMO.TIMCODE.BATCHING_TIMCODE, element: <RequireAuth><TimcodeFinalResultTimcode /></RequireAuth> },
    { path: ROUTES.DEMO.TIMCODE.BATCHING_RESULT, element: <RequireAuth><TimcodeBatching /></RequireAuth> },
    { path: "demo/notion", element: <DemoNotion /> },
    { path: "demo/picking", element: <DemoPicking /> },
    { path: "demo/heatmap/sap", element: <DemoHeatmapSAP /> },
    { path: "demo/sap/order/list", element: <RequireAuth><SAPOrdersList /></RequireAuth> }
];
