import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import "./MenuAdmin.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix, IconProp } from '@fortawesome/fontawesome-svg-core';
import { ROUTES } from 'resources/routes-constants';
import { userProfilActions } from 'store/reducers/Profil/userProfilSlice';
import _ from 'lodash';
import GlobalSearchForm from '../Admin/forms/GlobalSearchForm';
import { ClientItem, MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import TenantSelector from 'components/selectors/TenantSelector';
import { selectTenantForClientThunk } from 'store/reducers/Profil/thrunks/selectTenantForClientThunk';
import MapSelector from 'components/selectors/MapSelector';
import MenuItem from './MenuItem';

const MenuAdmin: React.FC = () => {
    const { t } = useTranslation(nameOf({MenuAdmin}), { useSuspense: false});
    const { 
        currentUser, 
        userInfos, 
        currentTenant 
    } = useAppSelector(state => state.userProfil);


    const allMaps = useAppSelector(state => state.adminClientMap.maps);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, mapId } = useParams();
    const currentMapSelected = mapId ? allMaps.find(m => m.id.toString() == mapId) : undefined;

    if(!currentUser) return null;

    let initial = "";
    let fullName = currentUser.email;

    if(userInfos && userInfos.firstName){
        initial += userInfos.firstName[0];
        fullName = userInfos.firstName;
    }
    if(userInfos && userInfos.lastName){
        initial +=  userInfos.lastName[0];
        fullName += (" " + userInfos.lastName);
    }
    
    const accountName = <Row id="account-name" className='m-3 align-items-center'>
        <Col className='px-0' xs="auto"><span className="user-photo" >{initial}</span></Col>
        <Col className='text-truncate'>
            <div><span className="user-name lato">{fullName}</span></div>
            <div><Link to={ROUTES.DASHBOARD} className='admin-btn'>Mode client</Link></div>
        </Col>
        <Col className='px-0' xs="auto"><button type="button" className='button-transparent' onClick={() => dispatch(userProfilActions.setLogout())}><span className="log-out" /></button></Col>
    </Row>


    const renderMenuItem = (label: string, to: string, iconName: IconName | undefined = undefined, iconPrefix: IconPrefix = "fas", subLevel = 0, className: string | undefined = undefined) => {

        const cssClass = "level-" + subLevel;

        return  <div className='menu-item'>
                <NavLink
                    className={({ isActive }) => cssClass  + " " + (isActive ? "selected" : undefined) + " " + className}
                    to={to}
                    style={({ isActive }) => isActive ? {} : undefined}>
                        <Row>
                            {subLevel > 0 && <Col sm={subLevel}></Col>}
                            {iconName && <Col sm="1"><FontAwesomeIcon icon={[iconPrefix, iconName]} size="lg"/></Col>}
                            <Col className='ms-2'>{label}</Col>
                        </Row>
                    
                </NavLink>
            </div>;
    }

    const onMapChange = (map: MapDetails) => {
        if(mapId)
        {
            // replace old map id in current url with new map id selected
            navigate(location.pathname.replaceAll(mapId, map.id), { replace: true });
        }
    }

    const onCurrentTenantChanged = (tenant: ClientItem) => {
        //Change client id in url
        const newPath = location.pathname.replace(`client/${currentTenant?.id}/`, `client/${tenant.id}/`)
        // select new client in the store
        dispatch(selectTenantForClientThunk(tenant));
        navigate(newPath);
    }
    
    const isUserDetailsPage = location.pathname.startsWith(ROUTES.ADMIN.USER().BASE_PATH + "/") && location.pathname != ROUTES.ADMIN.USER().CREATE
    const isClientSelected = !!currentTenant;
    const isMapSelected = !!currentMapSelected;
    const is3dSection = location.pathname.includes("/3d/");
    const isIntermarcheClient = isClientSelected && currentTenant.tenant == "intermarche";
    const isChronodriveClient = isClientSelected && currentTenant.tenant == "chronodrive";

    return (
        <div id="menu-admin">
            <div>{accountName}</div>
            <div><GlobalSearchForm /></div>
            <div className='menu-items mt-4'>
                {!isClientSelected && <>
                    <MenuItem label={t('Tableau de bord')} to={ROUTES.ADMIN.DASHBOARD} iconName='chart-line' />
                    <MenuItem label={t('Clients')} to={ROUTES.ADMIN.CLIENTS} iconName='database' />
                    {location.pathname == ROUTES.ADMIN.CLIENT_CREATE && <MenuItem label={t('Nouveau')} to={ROUTES.ADMIN.CLIENT_CREATE} iconName='plus' subLevel={1} />}
                    <MenuItem label={t('Utilisateurs')} to={ROUTES.ADMIN.USERS} iconName='users' />
                    {location.pathname == ROUTES.ADMIN.USER().CREATE && <MenuItem label={t('Nouveau')} to={ROUTES.ADMIN.USER().CREATE} iconName='plus' subLevel={1} />}
                    {isUserDetailsPage && <MenuItem label={t('Détails')} to={ROUTES.ADMIN.USER(userId).DETAILS} iconName='edit' subLevel={1} />}
                    <MenuItem label={t('Traductions backoffice')} to={ROUTES.ADMIN.TRANSLATIONS} iconName='globe' />
                    <MenuItem label={t('Picking')} to={ROUTES.ADMIN.PICKING.INDEX} iconName='box' />
                    {location.pathname.startsWith("/admin/picking/") && <MenuItem label={t('Middlewares')} to={ROUTES.ADMIN.PICKING.MIDDLEWARES} iconName='server' subLevel={1} />}
                    {location.pathname.startsWith("/admin/picking/") && <MenuItem label={t('Runners')} to={ROUTES.ADMIN.PICKING.RUNNERS} iconName='boxes-packing' subLevel={1} />}
                    <MenuItem label={t('Temps réel')} to={ROUTES.ADMIN.REALTIME.INDEX} iconName='bolt' />
                    {location.pathname.startsWith("/admin/realtime") && <MenuItem label={t('Changements carte')} to={ROUTES.ADMIN.REALTIME.MAP_CHANGES} iconName='cubes' subLevel={1} />}
                    {location.pathname.startsWith("/admin/realtime") && <MenuItem label={t('Utilisateurs connectés')} to={ROUTES.ADMIN.REALTIME.CONNECTED_USERS} iconName='network-wired' subLevel={1} />}
                </>}

                {isClientSelected && <>
                    <TenantSelector 
                        tenants={currentUser.tenants} 
                        currentTenant={currentTenant} 
                        onTenantChange={onCurrentTenantChanged}/>
                    {!isMapSelected && <>
                        <MenuItem label={t('Lieux')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAPS} iconName='location-dot' />
                        {location.pathname == ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_CREATE && <MenuItem label={t('Nouveau')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_CREATE} iconName='plus' subLevel={1} />}
                        <MenuItem label={t('Détails')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).DETAILS} iconName='edit' />
                        <MenuItem label={t('Permissions')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).PERMISSIONS} iconName='key' />
                        <MenuItem label={t('Clés API')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).API_TOKENS} iconName='link' />
                        <MenuItem label={t('Picking requêtes')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).PICKING_REQUESTS} iconName='cubes-stacked' />
                        {isIntermarcheClient && <MenuItem label='Test middleware' to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).INTERMARCHE_MIDDLEWARE_TEST} iconName='play' />}
                        {isChronodriveClient && <MenuItem label='Test middleware' to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).CHRONODRIVE_MIDDLEWARE_TEST} iconName='play' />}
                        <MenuItem label={t('Storage')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).FILE_MANAGER} iconName='folder-open' />
                        <MenuItem label={t('Retour')} to={ROUTES.ADMIN.CLIENTS} iconName='chevron-left' className='back-btn' />
                    </>}

                    {isMapSelected && <>
                        <MapSelector 
                            allMaps={allMaps} 
                            currentMap={currentMapSelected} 
                            onMapChange={onMapChange}/>
                        <MenuItem label={t("Détails")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_DETAILS(currentMapSelected.id)} iconName='edit' />
                        <MenuItem label={t("Unity 3D")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ASSETS(currentMapSelected.id)} iconName='cube' />
                        {is3dSection && <>
                            <MenuItem label={t("Assets") + ` (${currentMapSelected.totalAssets})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ASSETS(currentMapSelected.id)} iconName='cube' subLevel={1} />
                            <MenuItem label={t("Asset templates")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ASSET_TEMPLATE(currentMapSelected.id)} iconName='tag' subLevel={1} />
                            <MenuItem label={t("Polygones") + ` (${currentMapSelected.totalPolygons})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_POLYGONS(currentMapSelected.id)} iconName='draw-polygon' subLevel={1} />
                            <MenuItem label={t("Polygone Templates") + ` (${currentMapSelected.totalTemplates})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_TEMPLATES(currentMapSelected.id)} iconName='tags' subLevel={1} />
                            <MenuItem label={t("Bâtiments") + ` (${currentMapSelected.totalBuildings})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_BUILDINGS(currentMapSelected.id)} iconName='building' subLevel={1} />
                            <MenuItem label={t("Etages") + ` (${currentMapSelected.totalFloors})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_FLOORS(currentMapSelected.id)} iconName='stairs' subLevel={1} />
                            <MenuItem label={t("Offmeshlinks") + ` (${currentMapSelected.totalOffMeshLinks})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_OFFMESHLINKS(currentMapSelected.id)} iconName='link' subLevel={1} />
                            <MenuItem label={t("Markers") + ` (${currentMapSelected.totalMarkers})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_MARKERS(currentMapSelected.id)} iconName='location-dot' subLevel={1} />
                            <MenuItem label={t("Labels") + ` (${currentMapSelected.totalLabels})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_LABELS(currentMapSelected.id)} iconName='tag' subLevel={1} />
                            <MenuItem label={t("Label templates")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_LABEL_TEMPLATES(currentMapSelected.id)} iconName='tags' subLevel={1} />
                            <MenuItem label={t("Marker templates") + ` (${currentMapSelected.totalMarkerTemplates})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_MARKER_TEMPLATES(currentMapSelected.id)} iconName='bookmark' subLevel={1} />
                            <MenuItem label={t("Navmeshes") + ` (${currentMapSelected.totalNavmeshes})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_NAVMESH(currentMapSelected.id)} iconName='layer-group' subLevel={1} />
                            <MenuItem label={t("Zones") + ` (${currentMapSelected.totalZones})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_ZONE(currentMapSelected.id)} iconName='vector-square' subLevel={1} />
                            <MenuItem label={t("Metadata") + ` (${currentMapSelected.totalMetadataGroups})`} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_METADATA_GROUPS(currentMapSelected.id)} iconName='table' subLevel={1} />
                            <MenuItem label={t("Configurations")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_3D_SETTING(currentMapSelected.id)} iconName='gears' subLevel={1} />
                        </>}
                        <MenuItem label={t("Picking setting")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_PICKING_SETTING(currentMapSelected.id)} iconName='boxes-packing' />
                        <MenuItem label={t("Audits")} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAP_AUDITS(currentMapSelected.id)} iconName='table' />
                        <MenuItem label={t('Retour')} to={ROUTES.ADMIN.CLIENT(currentTenant.id.toString()).MAPS} iconName='chevron-left' className='back-btn' />
                    </>}
                </>}
            </div>
        </div>
    )
}

export default MenuAdmin
