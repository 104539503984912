import { RouteObject } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import LayoutChrono from './LayoutChrono';
import PoolsList from './Pages/PoolsList';
import SiteSetting from './Pages/SiteSettings';
import StatisticChrono from './Pages/Statistics/StatisticChrono';
import RunnerResultList from './Pages/RunnerResultList';
import MissionOptimizedList from './Pages/MissionOptimizedList';
import RequireAuth from 'components/Routes/RequireAuth';

/**
 * The specifics routes Chronodrive client.
 */
export const ChronodriveRoutes: RouteObject[] = [
    { path: ROUTES.BATCHING_CHRONO_POOLS, element: <RequireAuth><LayoutChrono><PoolsList /></LayoutChrono></RequireAuth> },
    { path: ROUTES.BATCHING_CHRONO_SETTINGS, element: <RequireAuth><LayoutChrono><SiteSetting /></LayoutChrono></RequireAuth> },
    { path: ROUTES.BATCHING_CHRONO_STATISTICS, element: <RequireAuth><LayoutChrono><StatisticChrono /></LayoutChrono></RequireAuth> },
    { path: ROUTES.BATCHING_CHRONO_RUNNER_BATCHES, element: <RequireAuth><LayoutChrono><RunnerResultList /></LayoutChrono></RequireAuth> },
    { path: ROUTES.BATCHING_CHRONO_RUNNER_MISSIONS, element: <RequireAuth><LayoutChrono><MissionOptimizedList /></LayoutChrono></RequireAuth> },
  ];

/*
const ChronodriveRoutes: React.FC = () => {
    return (
        <React.Fragment>
            <Route path={ROUTES.BATCHING_CHRONO_POOLS} element={<RequireAuth><LayoutChrono><PoolsList /></LayoutChrono></RequireAuth>} />
            <Route path={ROUTES.BATCHING_CHRONO_SETTINGS} element={<RequireAuth><LayoutChrono><SiteSetting /></LayoutChrono></RequireAuth>} />
            <Route path={ROUTES.BATCHING_CHRONO_STATISTICS} element={<RequireAuth><LayoutChrono><StatisticChrono /></LayoutChrono></RequireAuth>} />
            <Route path={ROUTES.BATCHING_CHRONO_RUNNER_BATCHES} element={<RequireAuth><LayoutChrono><RunnerResultList /></LayoutChrono></RequireAuth>} />
            <Route path={ROUTES.BATCHING_CHRONO_RUNNER_MISSIONS} element={<RequireAuth><LayoutChrono><MissionOptimizedList /></LayoutChrono></RequireAuth>} />
        </React.Fragment>
    );
};

export default ChronodriveRoutes;
*/