import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/store';
import SelectLarge from '../inputs/SelectLarge';
import { selectCurrentMapThrunks } from 'store/reducers/Map/thrunks/selectCurrentMapThrunks';
import { MapDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { useTranslation } from 'react-i18next';
import nameOf from 'utility/nameOf';

interface OwnProps {
    onMapChange: (map: MapDetails) => void;
    allMaps: MapDetails[];
    currentMap?: MapDetails;
    isClearable?: boolean;
}

/**
 * Tenant selector component
 * @returns 
 */
const MapSelector: React.FC<OwnProps> = ({allMaps, onMapChange, currentMap = undefined, isClearable = false}) => {
    const { t } = useTranslation(nameOf({MapSelector}), { useSuspense: false});
    const dispatch = useAppDispatch();

    if(allMaps)
    {
        return <SelectLarge options={allMaps} 
                        isDisabled = {allMaps.length < 2}
                        isClearable={isClearable}
                        placeholder={t("Sélectionner une map ...")}
                        noOption={t("Aucune map")}
                        value={currentMap} 
                        onChange={onMapChange} 
                        valueRender={(place) =><div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "location-dot"]} /> 
                            {place.name.length > 30 ? place.name.slice(0, 20) + "...": place.name} {place.reference ? `(ref: ${place.reference})` : ""}
                        </div>}
                        optionRender={(place) => <div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "location-dot"]} />  
                            {place.name} {place.reference ? `(ref: ${place.reference})` : ""}
                        </div>}/>
    }
    else 
    {
        return <></>
    }
}

export default MapSelector