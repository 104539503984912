import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import DataTable, { TableColumn } from 'react-data-table-component';
import { ChargeStatusEnum, DistributionEnum, OptimizationCompletionEnum, PoolDetails, ProductDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import dayjs from 'dayjs';
import { Badge, Button, Form, InputGroup, Offcanvas } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Select from 'react-select';
import ButtonFno from 'components/inputs/ButtonFno';
import { loadPoolsThunk } from 'store/reducers/Chronodrive/thunks/loadPoolsThunk';
import "./PoolsList.scss";
import { PoolStatusEnum } from 'backend/ApiChronodriveDefinition/data-contracts';
import PageHeader from 'components/PageHeader';
import Loading from 'components/Loading';
import IPoolSearchCriteria from 'store/reducers/Chronodrive/models/IPoolSearchCriteria';
import HelperTooltip from 'components/HelperTooltip';
import RelativeDateTime from 'components/RelativeDateTime';

const PoolsList: React.FC = () => {
    const { t } = useTranslation(nameOf({PoolsList}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        currentMap,
    } = useAppSelector(state => state.map);

    const createDefaultOption = (label: string) => ({ value: '', label });

    const {
        poolSearchResult,
        poolSearchCriteria,
        siteSettings,
        loadingPools
    } = useAppSelector(state => state.middlewareChrono);

    const [poolIdSearchTerm, setPoolIdSearchTerm] = useState<number | undefined>(poolSearchCriteria.poolId);
    const [poolProducts, setPoolProducts] = useState<ProductDetails[] | undefined>(undefined);

    useEffect(() => {
        // when middleware or selected map changed
        if(middlewareSelected && currentMap)
        {
            loadPools(poolSearchCriteria);
        }
    }, [middlewareSelected, currentMap]);


const getOptimizationCompletionBadge = (status: OptimizationCompletionEnum) => {
    switch (status) {
        case 'IGNORE':
            return <Badge bg="danger"><FontAwesomeIcon icon={['fas', 'ban']}/> {status}</Badge>;
        case 'PARTIAL':
            return <Badge bg="warning"><FontAwesomeIcon icon={['fas', 'warning']}/> {status}</Badge>;
        case 'COMPLET':
            return <Badge bg="success"><FontAwesomeIcon icon={['fas', 'check']}/> {status}</Badge>;
        case 'WAITING_OPTIMIZATION':
            return <Badge bg="primary"><FontAwesomeIcon icon={['fas', 'hourglass']}/> {status}</Badge>;
        default:
            return status;
    }
};


const getPoolStatus = (status: PoolStatusEnum) => {
    switch (status) {
        case 'AVAILABLE':
            return <Badge bg="success">{status}</Badge>;
        case 'UNAVAILABLE':
            return <Badge bg="danger">{status}</Badge>;
        default:
            return status;
    }
};


const getDistributionStatus = (status: DistributionEnum) => {
    switch (status) {
        case 'WAITING':
            return <Badge bg="primary"><FontAwesomeIcon icon={['fas', 'hourglass']}/> {status}</Badge>;
        case 'DISTRIBUTED':
            return <Badge bg="success"><FontAwesomeIcon icon={['fas', 'check']}/> {status}</Badge>;
        case 'OVERDUE':
            return <Badge bg="danger"> {status}</Badge>;
        default:
            return status;
    }
};


const getDistributionTypeStatus = (status: ChargeStatusEnum) => {

    
    switch (status) {
        case 'WARNING':
            return <Badge bg="primary">{status}</Badge>;
        case 'NORMAL':
            return <Badge bg="success">{status}</Badge>;
        case 'URGENT':
            return <Badge bg="danger"> {status}</Badge>;
        default:
            return status;
    }
};

const columns: TableColumn<PoolDetails>[] = [
    {
        id: 'OrderId',
        name: t('Order ID'),
        selector: row => row.orderId,
        sortable: false,
    },
    {
        id: 'PoolId',
        name: t('Pool ID'),
        selector: row => row.poolId,
        sortable: false,
    },
    {
        id: 'SectorCode',
        name: t('Sector Code'),
        selector: row => row.sectorCode,
        sortable: true,
    },
    {
        id: 'Pickings',
        name: t('Empl (qté)'),
        selector: row => row.pickings.length,
        sortable: false,
        width: '90px',
        cell: row => {
            const totalQuantity = row.pickings?.reduce((acc, pick) => acc + pick.quantity, 0) ?? 0;
            const nbProducts = row.pickings.length;
            return <>
                {nbProducts > 0 ?<Button size='sm' variant='link' className='p-0' title={t('{{nbEmplacements}} emplacements pour {{totalQuantity}} produits', {nbEmplacements:nbProducts,  totalQuantity: totalQuantity})} onClick={() => setPoolProducts(row.pickings)}> {nbProducts} ({totalQuantity})</Button> : 0}
            </>
        }
    },

    {
        id: 'Status',
        width: '100px',
        name: <><HelperTooltip  text={t("Status géré par le client")} placement='top' size='lg' /> <span className='ms-2'>{t('Status')}</span></>,
        selector: row => row.status,
        cell: (row) => getPoolStatus(row.status),
        sortable: true,
    },
    {
        id: 'DistributionStatus',
        width: '100px',
        name:  <><HelperTooltip  text={t("Indique si le pool a été envoyé au client")} placement='top' size='lg' /> <span className='ms-2'>{t('Distribution')}</span></>,
        selector: row => row.distributionStatus,
        cell: (row) => getDistributionStatus(row.distributionStatus),
        sortable: true,
    },
    {
        id: 'DistributionTypeStatus',
        width: '100px',
        name:  <><HelperTooltip  text={t("Indique comment le pool a été traité (mode urgent ou normal)")} placement='top' size='lg' /> <span className='ms-2'>{t('Type distribution')}</span></>,
        selector: row => row.distributionStatus,
        cell: (row) => getDistributionTypeStatus(row.distributionTypeStatus),
        sortable: false,
    },
    {
        id: 'OptimizationCompletion',
        name: <><HelperTooltip  text={t("Indique si le pool a été optimisé")} placement='top' size='lg' /> <span className='ms-2'>{t('Optimisation')}</span></>,
        selector: row => row.optimizationCompletion,
        cell: (row) => getOptimizationCompletionBadge(row.optimizationCompletion),
        sortable: true,
    },
    {
        id: 'DepartureTime',
        name: t('Departure Time'),
        selector: row => dayjs(row.departureTime).format('DD/MM/YYYY HH:mm'),
        cell: row => <RelativeDateTime date={row.departureTime} />,
        sortable: false,
    },
    {
        id: 'ReadyDateTime',
        name: t('Ready Date Time'),
        selector: row => dayjs(row.readyDateTime).format('DD/MM/YYYY HH:mm'),
        cell: row => <RelativeDateTime date={row.readyDateTime} />,
        sortable: true,
    },
    {
        id: 'DistributionDate',
        name: t('Date distribution'),
        selector: row => row.distributionDate ? dayjs(row.distributionDate).format('DD/MM/YYYY HH:mm') : "",
        cell: row => row.distributionDate ? <RelativeDateTime date={row.distributionDate} /> : "",
        sortable: true,
    },
    {
        id: 'DateCreated',
        name: t('Création'),
        selector: row => dayjs(row.dateCreated).format('DD/MM/YYYY HH:mm'),
        //cell: row => <RelativeDateTime date={row.dateCreated} />,
        sortable: true,
    },
];

    const pools = poolSearchResult?.items ?? [];

    const sectorCodeOptions = _.map(siteSettings, (sectorCode) => ({ value: sectorCode.sectorCode, label: sectorCode.sectorCode }));
    sectorCodeOptions.unshift(createDefaultOption(t('Tous les secteurs')));


    const poolStatusOptions = Object.keys(PoolStatusEnum).map(key => ({
        value: key,
        label: key
    }));

    poolStatusOptions.unshift(createDefaultOption(t('Tous les statuts')));

    const distributionStatusOptions = Object.keys(DistributionEnum).map(key => ({
        value: key,
        label: key
    }));
    distributionStatusOptions.unshift(createDefaultOption(t('Toutes les distributions')));



    const onSelectStatus = (status: string) => {
        const validStatus = Object.values(PoolStatusEnum).includes(status as PoolStatusEnum) ? (PoolStatusEnum as any)[status] : undefined;
        loadPools({...poolSearchCriteria, status: validStatus});
    }

    const onSelectDistribution = (status: string) => {
        const validDistribution = Object.values(DistributionEnum).includes(status as DistributionEnum) ? (DistributionEnum as any)[status] : undefined;
        loadPools({...poolSearchCriteria, distributionEnum: validDistribution});
    }

    const onPoolIdChange = (poolId: number | undefined) => {
        loadPools({...poolSearchCriteria, poolId: poolId});
    }
    
    const loadPools = (criteria: IPoolSearchCriteria) => {

        dispatch(loadPoolsThunk(criteria));
    };

    return (
        <div id='pool-list'>
            <PageHeader title={t("Pools")}/>
            <TableWithCrudComponent
                entities={pools} 
                columns={columns}
                loadingList={loadingPools}
                loadingForm={false}
                addDefaultActionsColumn={false}
                hideIdColumn={true}
                serverSide={true}
                onChangePage={(page) => {
                    loadPools({...poolSearchCriteria, currentPage: page});
                }}
                onChangeRowsPerPage={(pageSize) => {
                    loadPools({...poolSearchCriteria, pageSize: pageSize});
                }}
                onSort={(sortField, sortDirection) => {
                    loadPools({...poolSearchCriteria, sortColumn: sortField.id?.toString(), sortDirection: sortDirection});
                }}
                
                paginationPerPage={poolSearchCriteria.pageSize ?? 10}
                paginationTotalRows={poolSearchResult?.totalCount ?? 0}
                defaultSortFieldId='ReadyDateTime'
                defaultSortFieldDirection='desc'
                translations={{
                    tableTitle: `${t("Liste des pools")} (${poolSearchResult?.totalCount})`,
                    noEntityText: t("Aucun pool trouvé"),
                    deleteText: (entity) => (""),
                    deleteTitle: (entity) => (""),
                    updateText: (entity) => (""),
                }}

                customHeader={<div className='d-flex justify-content-between align-items-center'>
                        <InputGroup className="me-2 w-25">
                            <Form.Control placeholder={t("Pool Id")} 
                                    value={poolIdSearchTerm ?? ""} 
                                    onChange={(e) => setPoolIdSearchTerm(isNaN(parseInt(e.currentTarget.value)) ? undefined : parseInt(e.currentTarget.value))}
                                    onBlur={() => { loadPools({...poolSearchCriteria, poolId: poolIdSearchTerm}); }}/>
                            <Button variant="outline-danger" disabled={!poolIdSearchTerm} onClick={() => {
                                setPoolIdSearchTerm(undefined)
                                loadPools({...poolSearchCriteria, poolId: undefined})
                            }}>
                                <FontAwesomeIcon icon={["fas", "xmark"]}/>
                            </Button>
                        </InputGroup>
                        <Select className='me-2 filter-select' 
                                options={poolStatusOptions} 
                                defaultValue={poolStatusOptions[0]}
                                value={poolStatusOptions.find(option => option.value === poolSearchCriteria.status)} 
                                onChange={code => onSelectStatus(code?.value ?? "")}/>

                        <Select className='me-2 filter-select' 
                                options={distributionStatusOptions} 
                                defaultValue={distributionStatusOptions[0]}
                                value={distributionStatusOptions.find(option => option.value === poolSearchCriteria.distributionEnum)} 
                                onChange={code => onSelectDistribution(code?.value ?? "")}
                                styles={{
                                    placeholder: (base) => ({ ...base, whiteSpace: 'nowrap' }),
                                }}/>

                        <Select className='me-2 filter-select' 
                                options={sectorCodeOptions} 
                                defaultValue={sectorCodeOptions[0]}
                                value={sectorCodeOptions.find(option => option.value === poolSearchCriteria.sector)} 
                                onChange={code => loadPools({...poolSearchCriteria, sector: code?.value ?? ""})}/>

                        <ButtonFno className='me-2' color='blue'  onClick={() => loadPools(poolSearchCriteria)}><FontAwesomeIcon icon={["fas", "refresh"]}/></ButtonFno>
                    </div>}
            />


        <Offcanvas  show={poolProducts != undefined} onHide={() => setPoolProducts(undefined)} >
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>{t("Liste des produits du pool")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
            <DataTable 
                dense
                columns={[{
                    id:"locationCode",
                    name: t("Emplacement ({{count}})", { count: poolProducts?.length }),
                    sortable: true,
                    selector: row => row.locationCode
                    },
                    {
                        id:"quantity",
                        name: t("Quantité  ({{count}})", { count: poolProducts?.reduce((acc, product) => acc + product.quantity, 0) ?? 0 }),
                        sortable: true,
                        selector: row => row.quantity
                }]} 
                data={poolProducts ?? []} 
                progressPending={false}
                progressComponent={<Loading text={t("Chargement")}/>}
                striped={true}
                highlightOnHover={true}
                noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun produit")}</div>}/>
            </Offcanvas.Body>
        </Offcanvas>
        </div>
    )
}
export default PoolsList