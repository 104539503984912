import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import './MenuItem.scss';

interface MenuItemProps {
    label: string;
    to: string;
    iconName: IconName;
    iconPrefix?: IconPrefix;
    className?: string;
    subLevel?: number;
}

const MenuItem: React.FC<MenuItemProps> = ({
    label,
    to,
    iconName,
    iconPrefix = "fas",
    className = "",
    subLevel = 0
}) => {
    const cssClass = "level-" + subLevel;

    return (
        <div className={`menu-item ${cssClass} ${className}`}>
            <NavLink
                className={({ isActive }) => isActive ? "selected" : undefined}
                to={to}
                style={({ isActive }) => isActive ? {} : undefined}>
                <Row>
                    {subLevel > 0 && <Col sm="1"></Col>}
                    <Col sm="1"><FontAwesomeIcon icon={[iconPrefix, iconName]} size="lg" /></Col>
                    <Col className='ms-2'>{label}</Col>
                </Row>
            </NavLink>
        </div>
    );
}

export default MenuItem;