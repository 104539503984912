import React, { useState } from 'react';

/**
 * Custom React hook that tracks the mouse position on the screen.
 *
 * @returns An object containing the current mouse position with `x` and `y` coordinates.
 *          If the mouse position is not yet determined, both `x` and `y` will be `null`.
 *
 * @example
 * const mousePosition = useMousePosition();
 * console.log(mousePosition.x, mousePosition.y);
 */
const useMousePosition = () => {
  const [
    mousePosition,
    setMousePosition
  ] = useState<{ x: number | null, y: number | null }>({ x: null, y: null });


  React.useEffect(() => {
    const updateMousePosition = (ev: MouseEvent) => {
      setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    window.addEventListener('mousemove', updateMousePosition);
    return () => {
      window.removeEventListener('mousemove', updateMousePosition);
    };
  }, []);
  return mousePosition;
};

export default useMousePosition;