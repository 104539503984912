import {chronoActions} from '../chronodriveSlice';
import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";
import IMissionOptimizedSearchCriteria from '../models/IMissionOptimizedSearchCriteria';


export const loadMissionOptimizedThunk = (
    criteria: IMissionOptimizedSearchCriteria): AppThunk => {
return async (dispatch, getState) => {
  try {
    const tenant = getState().userProfil.currentTenant?.tenant?? ""
    const baseUrl = getState().middlewareApis.middlewareSelected?.baseUrl ?? "";
    const currentMap = getState().map.currentMap;
    const mapId = currentMap?.reference ?? currentMap?.id ?? "";

    dispatch(chronoActions.setLoadingMissionOptimizedResults(true));
    // set the runner result search criteria
    dispatch(chronoActions.setMissionOptimizedSearchCriteria(criteria));
    
    const searchResult = await apiChronodriveDefinition(baseUrl, tenant, mapId)
                              .missionOptimized.getMissions({
                                page: criteria.currentPage,
                                pageSize: criteria.pageSize,
                                sortColumn: criteria.sortColumn,
                                sector: criteria.sector,
                                batchId: criteria.batchId,
                                poolId: criteria.poolId,
                                sortDirection: criteria.sortDirection});
                    
    // Set the runner results in the store
    dispatch(chronoActions.setMissionOptimizedResults(searchResult.data));

  } catch (error: any) {

    if (!error.status || error.status != 401) {
      console.error("[loadMissionOptimizedThunk] Error while loading pools", error);
    }
  } finally {
    // Set loading state back to false after the operation completes
    dispatch(chronoActions.setLoadingMissionOptimizedResults(false));
  }
};
};