import { AppThunk } from "store/store"
import _ from "lodash";
import apiChronodriveDefinition from "backend/apiChronodriveDefinition";
import { toast } from "react-toastify";
import i18next from "i18next";


export const startBatchingForSectorThunk = (
    sector: string[]): AppThunk => {
return async (dispatch, getState) => {
  try {
    const tenant = getState().userProfil.currentTenant?.tenant?? ""
    const baseUrl = getState().middlewareApis.middlewareSelected?.baseUrl ?? "";
    const currentMap = getState().map.currentMap;
    const mapId = currentMap?.reference ?? currentMap?.id ?? "";


    await Promise.all(sector.map(async (sector) => {
      return apiChronodriveDefinition(baseUrl, tenant, mapId).batching.optimizeSector({sectorCode: sector});
    }));

  } catch (error: any) {

    if (!error.status || error.status != 401) {
      console.error("[startBatchingForSectorThunk] Error while loading pools", error);
    }
  }finally {
    // Set loading state back to false after the operation
    toast.success(i18next.t("Le(s) batch(es) ont été lancés avec succès"));
  }
};
};