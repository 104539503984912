import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, useAppSelector, useAppDispatch } from 'store/store';
import { ROUTES } from 'resources/routes-constants';
import { loginActions } from 'store/reducers/Login/loginSlice';
import Layout from 'pages/Layout';

/**
 * Redirects to the login page if the user is not authenticated.
 * @param {React.ReactNode} children - The child components to render if the user is authenticated.
 */
const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const currentUser = useSelector((state: RootState) => state.userProfil.currentUser);
    const redirectTo = useAppSelector(state => state.login.redirectUrl);
    const dispatch = useAppDispatch();
    const location = useLocation();

    if (!currentUser) {
        return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />;
    }
    // if redirectTo exists, force redirection
    else if (redirectTo) {
        dispatch(loginActions.setRedirectUrl(undefined));
        return <Navigate to={redirectTo} state={{ from: location }} replace />;
    }

    return <Layout>{children}</Layout>;
};

export default RequireAuth;
