import { SiteSettingDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import PageHeader from 'components/PageHeader';
import React, { useEffect } from 'react'
import { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createOrUpdateSetting } from 'store/reducers/Chronodrive/thunks/createOrUpdateSetting';
import { deleteSetting } from 'store/reducers/Chronodrive/thunks/deleteSetting';
import { loadAllSiteSettings } from 'store/reducers/Chronodrive/thunks/loadAllSiteSettings';
import { useAppDispatch, useAppSelector } from 'store/store';
import nameOf from 'utility/nameOf';
import parseApiErrorToast from 'utility/parseApiErrorToast';

const SiteSetting: React.FC = () => {
   const { t } = useTranslation(nameOf({SiteSetting}), { useSuspense: false});
   const dispatch = useAppDispatch();

    const {
        siteSettings,
        loadingGetSiteSettings: loadingSiteSettings
    } = useAppSelector(state => state.middlewareChrono);

    const {
        currentMap,
    } = useAppSelector(state => state.map);

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    useEffect(() => {
        // when middleware or selected map changed
        if(middlewareSelected && currentMap)
        {
            dispatch(loadAllSiteSettings());
        }
    }, [middlewareSelected, currentMap]);
   

   const onCreate = (setting: SiteSettingDetails, closeModal: () => void) => {
            dispatch(createOrUpdateSetting(setting,{
                onSuccess: (setting) => {
                    closeModal();
                    toast.success(t("La configuration a bien été créée"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            }))
       }
       
       const onUpdate = (setting: SiteSettingDetails, closeModal: () => void) => {
        dispatch(createOrUpdateSetting(setting,{
            onSuccess: (setting) => {
                closeModal();
                toast.success(t("La configuration a bien été modifiée"))
            },
            onError: (error) => {
                parseApiErrorToast(error);
            }
        }))
       }
   
       const onDelete = (setting: SiteSettingDetails, closeModal: () => void) => {
            dispatch(deleteSetting(setting,{
                onSuccess: (setting) => {
                    closeModal();
                    toast.success(t("La configuration a bien été créée"))
                },
                onError: (error) => {
                    parseApiErrorToast(error);
                }
            })) 
       }



       const columns: TableColumn<SiteSettingDetails>[] = [
        {
            id:'sectorCode',
            name: t('Secteur'),
            sortable: true,
            selector: row => row.sectorCode ?? "",
        },
        {
            id:'articleBy',
            name: t("Nb articles"),
            sortable: false,
            cell: (row) => <div>
                <div>{t("Par pool")} : <b>{row.nbMaxArticleByPool}</b></div> 
                <div>{t("Par box")} : <b>{row.nbMaxArticleByBox}</b></div>
            </div>
        },
        {
            id:'commandBatch',
            name: t("Commandes par batch"),
            sortable: false,
            selector: row => row.nbMaxCommandBatch ?? "",
            cell: (row) => <div>
                <div>{t("min")} : <b>{row.nbMinCommandBatch}</b></div> 
                <div>{t("max")} : <b>{row.nbMaxCommandBatch}</b></div>
            </div>
        },
        {
            id:'produitBatch',
            name: t("Produits par batch"),
            sortable: false,
            selector: row => row.nbMaxProduitBatch ?? "",
            cell: (row) => <div>
            <div>{t("min")} : <b>{row.nbMinProduitBatch}</b></div> 
            <div>{t("max")} : <b>{row.nbMaxProduitBatch}</b></div>
        </div>
        },
        {
            id:'supportBatch',
            name: t("Supports par batch"),
            sortable: false,
            selector: row => row.nbMaxSupportBatch ?? "",
            cell: (row) => <div>
            <div>{t("min")} : <b>{row.nbMinSupportBatch}</b></div> 
            <div>{t("max")} : <b>{row.nbMaxSupportBatch}</b></div>
        </div>
        },
        {
            id:'times',
            name: t("temps"),
            sortable: false,
            cell: (row) => <div>
                <div>{t("Moyen/article")} : <b>{row.averagePickingTimeByProductSec + " sec"} </b></div> 
                <div>{t("Sécurité")} : <b>{row.securityTimeSeconds + " sec"}</b></div>
            </div>
        },
        {
            id:'periods',
            name: t("Mode urgence si"),
            sortable: false,
            cell: (row) => <div>
                <div>{t("{{pools}} pools sur {{period}} min", {pools: row.maxPoolPerPeriod, period: row.periodInMinute})}</div> 
            </div>
        },
        {
            id:'limitedOptimization',
            name: t("Optimisation limitée"),
            sortable: false,
            cell: (row) => {

                if(!row.hourChangeBehaviour) return <>N/C</>

                return <div>
                <div>{t("avant {{hour}}h, optimisé sur {{maxHourPools}} prochaines heures", {hour: row.hourChangeBehaviour, maxHourPools: row.maxHourPoolTakenForLimitedOptimization})}</div> 
            </div>
            }
        },
        {
            id:'startLocationCode',
            name: t("Départ et arrivée"),
            sortable: false,
            selector: row => row.startLocationCode ?? "",
            cell: (row) => <div>
            <div>{row.startLocationCode}</div> 
            <div>{row.endLocationCode}</div>
        </div>
        },
       
    ];

   return (<div>
    <PageHeader title={t("Gestion des configurations par secteur")}/>
    <div>
    <TableWithCrudComponent 
            entities={siteSettings} 
            columns={columns}
            loadingForm={loadingSiteSettings}
            loadingList={loadingSiteSettings}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onCreate={onCreate}
            translations={{
                tableTitle: t("Configuration par secteur"),
                noEntityText: t("Aucune configuration disponible"),
                createTitle: t("Ajouter une configuration"),
                createButtonText: t("Créer une configuration"),
                deleteText: (entity) => (t("Voulez-vous supprimer la configuration ?")),
                deleteTitle: (entity) => (t("Supprimer la configuration pour le secteur {{sectorCode}}", {sectorCode: entity.sectorCode}) ),
                updateText: (entity) => (t("Modifier la configuration pour le secteur {{sectorCode}}", {sectorCode: entity.sectorCode})),
            }}
            customHeader={<> </>}
            fieldSearchable={(entity) => [
                entity.id,
                entity.sectorCode
            ]}
            initialValueCreateForm={{
                siteId: currentMap?.reference ?? "",
                sectorCode: "",
                averagePickingTimeByProductSec: 20,
                nbMinSupportBatch: 2,
                nbMaxSupportBatch: 6,
                nbMinCommandBatch: 2,
                nbMaxCommandBatch: 6,
                nbMinProduitBatch: 1,
                nbMaxProduitBatch: 300,
                nbMaxArticleByPool: 36,
                securityTimeSeconds: 68,
                nbMaxArticleByBox: 15,
                maxHourPoolTakenForLimitedOptimization: 3,
                maxPoolPerPeriod: 2,
                periodInMinute: 30,
                hourChangeBehaviour: 9,
                startLocationCode: "start",
                endLocationCode: "end"
            } as SiteSettingDetails}
            />
    </div>
</div> )
}
export default SiteSetting