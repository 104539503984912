import React from 'react';
import LineChart from 'components/Charts/LineChart';
import appColors from 'resources/appColors';
import { useTranslation } from 'react-i18next';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import { StatisticsDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import nameOf from 'utility/nameOf';

const DurationChart: React.FC<{ labels: string[], statistics: StatisticsDetails}> = ({ labels, statistics }) => {
    const { t } = useTranslation(nameOf({DurationChart}), { useSuspense: false});

    const datasetAverageProcessDuration = {
        data: Object.values(statistics?.averageProcessDurationPerDay ?? {}).map(duration => (duration.average ?? 0) / 1000),
        label: t("Durée moyenne (sec)"),
        color: appColors.blue,
    } as IDatasetModel;

    const datasetMinProcessDuration = {
        data: Object.values(statistics?.averageProcessDurationPerDay ?? {}).map(duration => (duration.min ?? 0) / 1000),
        label: t("Durée min (sec)"),
        color: appColors.green,
    } as IDatasetModel;

    const datasetMaxProcessDuration = {
        data: Object.values(statistics?.averageProcessDurationPerDay ?? {}).map(duration => (duration.max ?? 0) / 1000),
        label: t("Durée max (sec)"),
        color: appColors.orange,
    } as IDatasetModel;

    return (
        <div className='table-component'>
            <header className='section-header'><h5 className='text-uppercase'>{t("Durée moyenne d'optimisation")}</h5></header>
            <LineChart labels={labels} datasets={[datasetAverageProcessDuration, datasetMinProcessDuration, datasetMaxProcessDuration]} />
        </div>
    );
}

export default DurationChart;
