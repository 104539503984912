import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";
dayjs.extend(utc);

export interface IMenuDatesSelectorSlice {
  showInput: boolean;
  dateFrom: Date;
  dateTo: Date;
}

const initialState: IMenuDatesSelectorSlice = {
  showInput: false,
  dateFrom: dayjs().startOf('day').add(-6, 'day').toDate(),
  dateTo: dayjs().endOf('day').toDate()
};

/**
 * Manage dates selector in the menu (date from ... to ...)
 */
const menuDatesSelectorSlice = createSlice({
  name: "menuDatesSelectorSlice",
  initialState,
  reducers: {
    setShowInput: (state, action: PayloadAction<boolean>) => {
      state.showInput = action.payload;
    },
    setDates: (state, action: PayloadAction<{from: Date, to: Date}>) => {


      state.dateFrom = action.payload.from;
      state.dateTo = action.payload.to;
    },
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      state = initialState;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: menuDatesSelectorActions,
  reducer: menuDatesSelectorReducer,
} = menuDatesSelectorSlice;
