import React from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import LayoutAdmin from 'pages/Admin/LayoutAdmin';

/**
 * Redirects to the dashboard if the user is not an admin.
 * @param {React.ReactNode} children - The child components to render if the user is an admin.
 */
const RequireAdmin: React.FC<{ children: JSX.Element }> = ({ children }) => {
    if (!currentUserIsAdmin()) {
        return <Navigate to={ROUTES.DASHBOARD} replace />;
    }

    return <LayoutAdmin>{children}</LayoutAdmin>;
};

export default RequireAdmin;
