import React from 'react';
import BarStackedChart from 'components/Charts/BarStackedChart';
import appColors from 'resources/appColors';
import { useTranslation } from 'react-i18next';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import _ from 'lodash';
import { StatisticsDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import nameOf from 'utility/nameOf';

const ProductsDistributedBySectorChart: React.FC<{ labels: string[], statistics: StatisticsDetails}> = ({ labels, statistics }) => {
    const { t } = useTranslation(nameOf({ProductsDistributedBySectorChart}), { useSuspense: false});
    const colors = [appColors.orange, appColors.green, appColors.blue, appColors.yellow, appColors.red];

    const allSectorCodes = _.uniq(
        Object.values(statistics?.nbProductsBySectorDistributed ?? {}).flatMap(sector => Object.keys(sector))
    );

    const nbRunnerCallsBySectorDatasets = allSectorCodes.map((sectorCode, index) => {
        return {
            data: Object.values(statistics?.nbProductsBySectorDistributed ?? {}).map(sector => sector[sectorCode] ?? 0),
            label: sectorCode,
            color: colors[index % colors.length] // Handle color array overflow
        } as IDatasetModel;
    });

    return (
        <div className='table-component'>
            <header className='section-header'><h5 className='text-uppercase'>{t("Nombre de produits distribués par secteur (Avec quantité)")}</h5></header>
            <BarStackedChart labels={labels} datasets={nbRunnerCallsBySectorDatasets} />
        </div>
    );
}

export default ProductsDistributedBySectorChart;
