import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import { showDatesSelectorThrunks } from 'store/reducers/MenuDatesSelector/thrunks/showDatesSelectorThrunks';
import { loadStatisticsThrunk } from 'store/reducers/Chronodrive/thunks/loadStatisticsThrunk';
import MissingLocationCodesCanvas from 'components/MissingLocationCodesCanvas';
import PoolsChart from './components/PoolsChart';
import RunnerCallsChart from './components/RunnerCallsChart';
import DurationChart from './components/DurationChart';
import StatisticCards from './components/StatisticCards';
import appColors from 'resources/appColors';
import PoolsByMissionsChart from './components/PoolsByMissionsChart';
import ProductsDistributedBySectorChart from './components/ProductsDistributedBySectorChart';
import ProductsByDistributionTypeChart from './components/ProductsByDistributionTypeChart';
import PoolsByMissionSectorChart from './components/PoolsByMissionSectorChart';

const colors = [appColors.orange, appColors.green, appColors.blue, appColors.yellow, appColors.red];

const StatisticChrono: React.FC = () => {
    const { t } = useTranslation(nameOf({StatisticChrono}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const mapSelected = useAppSelector(state => state.map.currentMap);
    const [showUnknownPage, setShowUnknownPage] = useState(false);

    const {
        statistics,
        loadingStatistics
    } = useAppSelector(state => state.middlewareChrono);

    const {
        middlewareSelected,
    } = useAppSelector(state => state.middlewareApis);

    const {
        dateFrom,
        dateTo,
    } = useAppSelector(state => state.menuDatesSelector);


    useEffect(() => {
        document.title = t('Statistiques');

        dispatch(showDatesSelectorThrunks(true))
        return () => {
            dispatch(showDatesSelectorThrunks(false))
        }
        
    }, [])

    useEffect(() => {
        loadStatistics();
    }, [middlewareSelected, mapSelected])

    useEffect(() => {
        loadStatistics();
    }, [dateFrom, dateTo])

    const loadStatistics = () => {
        if(middlewareSelected && mapSelected)
        {
            dispatch(loadStatisticsThrunk());
        }
    }

    const labels = [];
    let currentDate = dayjs(dateFrom);
    while (currentDate.isBefore(dayjs(dateTo)) || currentDate.isSame(dayjs(dateTo))) {
        labels.push(currentDate.format("DD/MM"));
        currentDate = currentDate.add(1, 'day');
    }

    return (
        <div>
             <PageHeader title={t("Statistiques")} middlePart={[{ to: ROUTES.DASHBOARD, title: t('Batching')}]}/>
            <div>
                {statistics && <StatisticCards statistics={statistics} loadingStatistics={loadingStatistics} setShowUnknownPage={setShowUnknownPage} />}
                <div>
                    <div className='float-end'>
                        <small>{t("*Sur la période du {{dateFrom}} au {{dateTo}}", { dateFrom: dayjs(dateFrom).format("DD/MM/YYYY"), dateTo: dayjs(dateTo).format("DD/MM/YYYY") })}</small>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            {statistics && <><Row>
                <Col>
                    <PoolsChart labels={labels} statistics={statistics} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ProductsDistributedBySectorChart labels={labels} statistics={statistics} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ProductsByDistributionTypeChart labels={labels} statistics={statistics} />
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <RunnerCallsChart labels={labels} statistics={statistics} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DurationChart labels={labels} statistics={statistics}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PoolsByMissionsChart labels={labels} statistics={statistics}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <PoolsByMissionSectorChart labels={labels} statistics={statistics}/>
                </Col>
            </Row></>}

            

            <MissingLocationCodesCanvas 
                loading={loadingStatistics}
                show={showUnknownPage}
                onHide={() => setShowUnknownPage(false)}
                locationCodes={statistics?.missiongLocationCodes ?? {}}
            />
        </div>
    )
}

export default StatisticChrono
