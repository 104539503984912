import React from 'react';
import { Col, Row } from 'react-bootstrap';
import StatisticCard from 'components/StatisticCard';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import dayjs from 'dayjs';
import { StatisticsDetails } from 'backend/ApiChronodriveDefinition/data-contracts';
import nameOf from 'utility/nameOf';

const StatisticCards: React.FC<{ statistics: StatisticsDetails, loadingStatistics: boolean, setShowUnknownPage: (show: boolean) => void }> = ({ statistics, loadingStatistics, setShowUnknownPage }) => {
    const { t } = useTranslation(nameOf({StatisticCards}), { useSuspense: false});

    const totalDaysPeriode = dayjs(statistics?.to).diff(dayjs(statistics?.from), 'day') + 1;
    const totalPoolsCreated = _.sumBy(Object.values(statistics?.poolsCreated ?? {}), count => count);
    const totalPoolsDistributed = _.sumBy(Object.values(statistics?.poolsDistributed ?? {}), count => count);
    const totalPoolsOverdue = _.sumBy(Object.values(statistics?.poolsOverdue ?? {}), count => count);
    const totalProducts = _.sumBy(Object.values(statistics?.nbProducts ?? {}), count => count);

    return (
        <Row className='justify-content-evenly'>
            {Object.entries(statistics?.missiongLocationCodes ?? {}).length > 0 && 
                <Col className='mb-3'><StatisticCard title={t("Adresses inconnues*")} color='orange' onClick={() => setShowUnknownPage(true)} value={`${Object.entries(statistics?.missiongLocationCodes ?? {}).length}`} icon='warning' loading={loadingStatistics}/></Col>}
                <Col className='mb-3'><StatisticCard title={t("Total pools/produits")} color='blue' value={`${totalPoolsCreated}/${totalProducts}`} icon='box' loading={loadingStatistics}/></Col>
                <Col className='mb-3'><StatisticCard title={t("Total distribuée")} color='green' value={`${totalPoolsDistributed}`} icon='check' loading={loadingStatistics}/></Col>
                <Col className='mb-3'><StatisticCard title={t("Total dépassés")} color='red' value={`${totalPoolsOverdue}`} icon='stopwatch' loading={loadingStatistics}/></Col>
                <Col className='mb-3'><StatisticCard title={t("Total jours*")} color='yellow' value={totalDaysPeriode.toLocaleString("fr-FR")} icon='calendar-days' loading={loadingStatistics}/></Col>
        </Row>
    );
}

export default StatisticCards;