import { RouteObject } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import AdminDashboard from 'pages/Admin/AdminDashboard';
import AdminTranslations from 'pages/Admin/AdminTranslations';
import PickingDashboard from 'pages/Admin/Picking/PickingDashboard';
import AdminMiddlewareApi from 'pages/Admin/Picking/Middleware/AdminMiddlewareApi';
import AdminClientPickingRunner from 'pages/Admin/Picking/PickingRunner/AdminClientPickingRunner';
import AdminRealtimeDashboard from 'pages/Admin/realtime/AdminRealtimeDashboard';
import AdminRealtimeMapChanges from 'pages/Admin/realtime/AdminRealtimeMapChanges';
import AdminRealtimeConnectedUsers from 'pages/Admin/realtime/AdminRealtimeConnectedUsers';
import AdminClients from 'pages/Admin/AdminClients';
import AdminUsers from 'pages/Admin/user/AdminUsers';
import AdminUserCreate from 'pages/Admin/user/AdminUserCreate';
import AdminUserDetails from 'pages/Admin/user/AdminUserDetails';
import AdminClientCreate from 'pages/Admin/AdminClientCreate';
import AdminClientMaps from 'pages/Admin/client/Map/AdminClientMaps';
import AdminClientDetails from 'pages/Admin/client/Details/AdminClientDetails';
import AdminClientPermissions from 'pages/Admin/client/Permissions/AdminClientPermissions';
import AdminClientApiTokens from 'pages/Admin/client/ApiToken/AdminClientApiTokens';
import MiddlewareRequestList from 'pages/Admin/client/MiddlewareRequest/MiddlewareRequestList';
import IntermarcheTestMiddleware from 'pages/Demos/IntermarcheTestMiddleware/IntermarcheTestMiddleware';
import AdminClientFileManager from 'pages/Admin/client/FileManager/AdminClientFileManager';
import ChronodriveMiddlewareTest from 'pages/Demos/chronodriveMiddlewareTest/ChronodriveMiddlewareTest';
import LayoutMapDetails from 'pages/Admin/client/Map/LayoutMapDetails';
import AdminClientMapEdit from 'pages/Admin/client/Map/AdminClientMapEdit';
import AdminClientMapViewer3D from 'pages/Admin/client/Map/AdminClientMapViewer3D';
import AdminClientMapCreate from 'pages/Admin/client/Map/AdminClientMapCreate';
import AdminClientMapPolygones from 'pages/Admin/client/Map/AdminClientMapPolygones';
import AdminClientMapBuildings from 'pages/Admin/client/Map/AdminClientMapBuildings';
import AdminClientMapAssets from 'pages/Admin/client/Map/AdminClientMapAssets';
import AdminClientMapAssetTemplates from 'pages/Admin/client/Map/AdminClientMapAssetTemplates';
import AdminClientMapMarkers from 'pages/Admin/client/Map/AdminClientMapMarkers';
import AdminClientMapLabels from 'pages/Admin/client/Map/AdminClientMapLabels';
import AdminClientMapLabelTemplates from 'pages/Admin/client/Map/AdminClientMapLabelTemplates';
import AdminClientMapMarkerTemplate from 'pages/Admin/client/Map/AdminClientMapMarkerTemplate';
import AdminClientMapFloors from 'pages/Admin/client/Map/AdminClientMapFloors';
import AdminClientMapOffMeshLink from 'pages/Admin/client/Map/AdminClientMapOffMeshLink';
import AdminClientMapNavmesh from 'pages/Admin/client/Map/AdminClientMapNavmesh';
import AdminClientMapSetting from 'pages/Admin/client/Map/AdminClientMapSetting';
import AdminClientMapZone from 'pages/Admin/client/Map/AdminClientMapZone';
import AdminClientMapMetadataGroup from 'pages/Admin/client/Map/AdminClientMapMetadataGroup';
import AdminClientMapPickingSetting from 'pages/Admin/client/Map/AdminClientMapPickingSetting';
import AdminClientMapAudits from 'pages/Admin/client/Map/AdminClientMapAudits';
import AdminClientMapTemplate from 'pages/Admin/client/Map/AdminClientMapTemplate';
import RequireAdmin from 'components/Routes/RequireAdmin';

export const AdminRoutes: RouteObject[] = [
    { path: ROUTES.ADMIN.DASHBOARD, element: <RequireAdmin><AdminDashboard /></RequireAdmin> },
    { path: ROUTES.ADMIN.TRANSLATIONS, element: <RequireAdmin><AdminTranslations /></RequireAdmin> },
    { path: ROUTES.ADMIN.PICKING.INDEX, element: <RequireAdmin><PickingDashboard /></RequireAdmin> },
    { path: ROUTES.ADMIN.PICKING.MIDDLEWARES, element: <RequireAdmin><AdminMiddlewareApi /></RequireAdmin> },
    { path: ROUTES.ADMIN.PICKING.RUNNERS, element: <RequireAdmin><AdminClientPickingRunner /></RequireAdmin> },
    { path: ROUTES.ADMIN.REALTIME.INDEX, element: <RequireAdmin><AdminRealtimeDashboard /></RequireAdmin> },
    { path: ROUTES.ADMIN.REALTIME.MAP_CHANGES, element: <RequireAdmin><AdminRealtimeMapChanges /></RequireAdmin> },
    { path: ROUTES.ADMIN.REALTIME.CONNECTED_USERS, element: <RequireAdmin><AdminRealtimeConnectedUsers /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENTS, element: <RequireAdmin><AdminClients /></RequireAdmin> },
    { path: ROUTES.ADMIN.USERS, element: <RequireAdmin><AdminUsers /></RequireAdmin> },
    { path: ROUTES.ADMIN.USER().CREATE, element: <RequireAdmin><AdminUserCreate /></RequireAdmin> },
    { path: ROUTES.ADMIN.USER(':userId').DETAILS, element: <RequireAdmin><AdminUserDetails /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT_CREATE, element: <RequireAdmin><AdminClientCreate /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAPS, element: <RequireAdmin><AdminClientMaps /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_DETAILS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapEdit /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_VIEWER_3D(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapViewer3D /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_CREATE, element: <RequireAdmin><LayoutMapDetails><AdminClientMapCreate /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_POLYGONS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapPolygones /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_ASSETS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapAssets /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_ASSET_TEMPLATE(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapAssetTemplates /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_BUILDINGS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapBuildings /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_MARKERS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapMarkers /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_LABELS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapLabels /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_LABEL_TEMPLATES(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapLabelTemplates /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_MARKER_TEMPLATES(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapMarkerTemplate /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_FLOORS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapFloors /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_OFFMESHLINKS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapOffMeshLink /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_NAVMESH(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapNavmesh /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_SETTING(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapSetting /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_ZONE(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapZone /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_METADATA_GROUPS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapMetadataGroup /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_PICKING_SETTING(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapPickingSetting /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_AUDITS(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapAudits /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').MAP_3D_TEMPLATES(":mapId"), element: <RequireAdmin><LayoutMapDetails><AdminClientMapTemplate /></LayoutMapDetails></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').DETAILS, element: <RequireAdmin><AdminClientDetails /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').PERMISSIONS, element: <RequireAdmin><AdminClientPermissions /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').API_TOKENS, element: <RequireAdmin><AdminClientApiTokens /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').PICKING_REQUESTS, element: <RequireAdmin><MiddlewareRequestList /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').INTERMARCHE_MIDDLEWARE_TEST, element: <RequireAdmin><IntermarcheTestMiddleware /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').FILE_MANAGER, element: <RequireAdmin><AdminClientFileManager /></RequireAdmin> },
    { path: ROUTES.ADMIN.CLIENT(':clientId').CHRONODRIVE_MIDDLEWARE_TEST, element: <RequireAdmin><ChronodriveMiddlewareTest /></RequireAdmin> }
];
